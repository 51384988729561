import { useToast } from '@/components/ui/use-toast'
import { LocalStorage } from '@/lib/local-storage'
import { Wallet } from '@canonicxyz/wallet-sdk'
import { atom, useAtom } from 'jotai'

const userAtom = atom<NonNullable<
  Awaited<ReturnType<typeof Wallet.getUser>>
> | null>(null)
const loadingAtom = atom(true)

export default function useUser() {
  const [user, setUser] = useAtom(userAtom)
  const [loading, setLoading] = useAtom(loadingAtom)
  const toast = useToast()

  const fetchUser = async () => {
    if (!Wallet.isLoggedIn() || !LocalStorage.getAuthToken()) {
      setLoading(false)
      return setUser(null)
    }

    setLoading(true)

    try {
      const user = await Wallet.getUser()

      if (user) {
        user.image = `${user.image}?buster=${+new Date()}`
        setUser(user)
      }
    } catch (err) {
      toast.toast({
        title: 'Error getting user',
        description: err instanceof Error && err.message,
        variant: 'destructive',
      })
    }

    setLoading(false)
  }

  const logout = async () => {
    LocalStorage.deleteAuthToken()
    Wallet.logout()
    await fetchUser()
  }

  return { user, fetchUser, loading, logout }
}
