import { formatCurrency } from '@/lib/formatting'
import { api } from '@/lib/api'
import { formatDateLong } from '@/lib/formatting'
import { AppRoutes } from '@/routes'
import { Link } from 'react-router-dom'
import { useResetWindowScroll } from '@/hooks/useResetWindowScroll'

const EXAMPLE_FEE_TEXT_LENGTH = 300

const exampleFeeText = new Array(EXAMPLE_FEE_TEXT_LENGTH).fill('a').join('')

export function DigDeeperPage() {
  useResetWindowScroll()

  const { data: stats } = api.archives.stats.useQuery()

  const { data: fee } = api.bitcoin.estimateFeesRawData.useQuery({
    title: 'Example Fee',
    size: exampleFeeText.length,
  })

  return (
    <div className="prose dark:prose-invert mx-auto mt-16 max-w-prose">
      <h1>Digging Deeper</h1>

      <p>
        The information that is saved on the final Bitcoin transaction is
        determined by you. This is what we would call saving it "on chain". We
        currently provide two options for saving content on chain:
      </p>

      <h3 id="hashes">1. Hashes</h3>
      <p>
        This is the cheapest option to store a page on chain. A hash doesn't
        actually contain any of the website content, but acts as a digital
        fingerprint for the page. If the page content changes, the hash (or
        fingerprint) will also change. This gives you verifiable proof that a
        website has been altered or changed. Another common term for this would
        be calling it the page's timestamp. Hashing is a core building block of
        most cryptocurrencies, including Bitcoin. Ark uses the SHA-256 hashing
        algorithm.
      </p>
      <p>
        <a
          href="https://www.codecademy.com/resources/blog/what-is-hashing/"
          target="_blank"
          rel="noreferrer"
        >
          Learn more about hashing &rarr;
        </a>
      </p>

      <h3 id="full-text-content">2. Full Text Content</h3>
      <p>
        While saving a hash is a good way to prove that a page hasn't changed
        since a specific point in time, it still doesn't actually save the page
        content on chain. While being more expensive (because of the larger
        amount of data), saving a page's full text content on chain is the best
        way to ensure it's recorded on the immutable blockchain. Text is
        extracted from the page and then saved in a format called markdown so
        that basic formatting and styling can still be applied when viewing it.
      </p>

      <p>
        <b>Example transctions:</b>
      </p>
      <ul>
        <li>
          <a
            href="https://ordiscan.com/inscription/76658485"
            target="_blank"
            rel="noreferrer"
          >
            Hash transaction
          </a>
        </li>
        <li>
          <a
            href="https://ordiscan.com/inscription/78166323"
            target="_blank"
            rel="noreferrer"
          >
            Full text transaction
          </a>
        </li>
      </ul>

      <h2 id="how-we-store-data-on-chain">How we store data on chain</h2>
      <p>
        Ark uses Bitcoin's ordinals protocol (numbering system) to inscribe your
        webpage text into transactions. Short text can be inscribed in a single
        transaction. Longer texts are split into chunks and inscribed in reverse
        chronological order, such that the final transaction contains the
        starting text and all information needed to locate the next (child)
        inscription.
      </p>
      <p>
        <a href="https://docs.ordinals.com/" target="_blank" rel="noreferrer">
          Learn more about ordinals &rarr;
        </a>
      </p>

      <h2 id="how-we-calculate-the-cost">How we calculate the cost</h2>
      <p>
        Costs to save a page on chain are calculated based on the size of the
        page (if you are saving the full page) and the current Bitcoin network
        fees. The network fees are constantly changing according to the
        network's demand, and will update approximately every 10 minutes. Ark
        currently sources the most up to date fees from{' '}
        <a href="https://mempool.space/" target="_blank" rel="noreferrer">
          mempool.space
        </a>
        .
      </p>
      <p>
        Currently, it is estimated to cost{' '}
        {formatCurrency(fee?.premiumFeesUsd ?? 0)} to save{' '}
        {EXAMPLE_FEE_TEXT_LENGTH} characters of text. View an archive in{' '}
        <Link to={AppRoutes.FEED}>our feed</Link> to view the fees for a
        specific page.
      </p>
      <p>
        As of today, {formatDateLong(+new Date())},{' '}
        {(stats?.totalArchives ?? 0).toLocaleString()} webpages have been
        archived on our servers, with{' '}
        {(stats?.onChainArchives ?? 0).toLocaleString()} of those etched
        permanently into the Bitcoin blockchain. See more details on our{' '}
        <Link to={AppRoutes.STATS}>stats page</Link>.
      </p>
    </div>
  )
}
