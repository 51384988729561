import { cn } from '@/lib/utils'
import { useAudioPlayer } from '@/hooks/useAudioPlayer'
import { AudioPlayer } from './AudioPlayer'

export function GlobalAudioPlayer() {
  const { audioUrl } = useAudioPlayer()

  const show = !!audioUrl

  return (
    <div
      className={`fixed inset-x-0 bottom-0 z-50 flex justify-end shadow-lg transition-all sm:inset-x-2 sm:bottom-2 ${cn(
        {
          'translate-y-0 opacity-100': show,
          'translate-y-full select-none opacity-0': !show,
        },
      )}`}
    >
      <div className="bg-background relative mr-[var(--removed-body-scroll-bar-size)] w-full max-w-xl rounded-lg border px-4 py-2 shadow-sm">
        <AudioPlayer />
      </div>
    </div>
  )
}
