import { useAtom } from 'jotai'

import { atom } from 'jotai'
import { useEffect } from 'react'

interface AudioPlayerState {
  isPlaying: boolean
  progress: number
  volume: number
  duration: number
  playbackRate: number
  audioUrl: string | null
  archiveUrl: string | null
  title: string | null
  artist?: string
  /** Optional link that the title should link to */
  titleLink?: string
}

const defaultState: AudioPlayerState = {
  isPlaying: false,
  progress: 0,
  volume: 1,
  duration: 0,
  playbackRate: 1,
  audioUrl: null,
  archiveUrl: null,
  title: null,
  artist: undefined,
  titleLink: undefined,
}

const audioPlayerAtom = atom<AudioPlayerState>(defaultState)
const shouldUpdateSeekAtom = atom(0)

const LOCAL_STORAGE = { CURRENTLY_PLAYING_URL: 'currently_playing_url' }

let timeout: NodeJS.Timeout

const SEEK_EVENT = 'audioPlayerSeek'

export function useAudioPlayer() {
  const [audioState, setAudioState] = useAtom(audioPlayerAtom)

  const [shouldUpdateSeek, setShouldUpdateSeek] = useAtom(shouldUpdateSeekAtom)

  const updateState = (newState: Partial<AudioPlayerState>) => {
    setAudioState((prev: AudioPlayerState) => {
      const state = { ...prev, ...newState }

      // if (prev.progress !== state.progress && onSeek) {
      //   onSeek(state.progress)
      // }

      clearTimeout(timeout)
      timeout = setTimeout(() => {
        updateLocalStorage(state)
      }, 750)

      return state
    })
  }

  const updateLocalStorage = (state: AudioPlayerState) => {
    if (state.audioUrl) {
      localStorage.setItem(LOCAL_STORAGE.CURRENTLY_PLAYING_URL, state.audioUrl)
      localStorage.setItem(state.audioUrl, JSON.stringify(state))
    } else {
      localStorage.removeItem(LOCAL_STORAGE.CURRENTLY_PLAYING_URL)
    }
  }

  const playUrl = (
    url: string,
    {
      title,
      artist,
      titleLink,
      archiveUrl,
    }: {
      title: string
      artist: string
      titleLink?: string
      archiveUrl?: string
    },
  ) => {
    const savedState = checkForSavedState(url)

    if (!savedState) {
      updateState({
        ...defaultState,
        audioUrl: url,
        archiveUrl,
        title,
        artist,
        titleLink,
        isPlaying: true,
      })
    }
  }

  const checkForSavedState = (url?: string): AudioPlayerState | null => {
    const u = url ?? localStorage.getItem(LOCAL_STORAGE.CURRENTLY_PLAYING_URL)
    if (!u) return null

    const rawState = localStorage.getItem(u)
    if (!rawState) return null

    try {
      const state = { ...JSON.parse(rawState), isPlaying: !!url }

      setAudioState({
        ...state,

        // Need to clear the progress value and have the player seek to
        // it so the internal ref is updated
        progress: 0,

        // If we're switching to a new url, we need to wait for the new duration
        // to be available from the player `onDuration` callback
        // before seeking to the saved progress
        duration: url ? 0 : state.duration,
      })

      setShouldUpdateSeek(state.progress)
      return state
    } catch {}

    return null
  }

  /** Use this when you need to seek to a specific position.
   * This is needed in order to sync the internal player state with the UI.
   */
  const handleSeek = (progress: number) => {
    updateState({ progress })

    window.dispatchEvent(new CustomEvent(SEEK_EVENT, { detail: { progress } }))
  }

  const onSeek = (cb: (progress: number) => void) => {
    window.addEventListener(SEEK_EVENT, (e: any) => {
      const progress: number = e.detail.progress

      cb(progress)
    })
  }

  const clearState = () => {
    updateState(defaultState)
  }

  return {
    ...audioState,
    updateState,
    playUrl,
    checkForSavedState,
    shouldUpdateSeek,
    setShouldUpdateSeek,
    onSeek,
    handleSeek,
    clearState,
  }
}
