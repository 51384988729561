import { useAnalytics } from '@/hooks/useAnalytics'
import { IArchive } from '@/types'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { BitcoinIcon } from 'lucide-react'
import { Button } from '@/components/ui/button'

interface Props {
  archive: IArchive
  setPayModal: (payModal: boolean) => void
}

export function Unsaved(props: Props) {
  const { archive, setPayModal } = props

  const analytics = useAnalytics()

  return (
    <div className="border-border flex items-center justify-between gap-2 rounded-md border px-4 py-2">
      <AlertTitle className="mb-0">
        This content is not saved on Bitcoin.
      </AlertTitle>
      <Button
        variant="theme"
        size="sm"
        onClick={() => {
          analytics.sendEvent('save_on_bitcoin', {
            url: archive.url,
          })

          setPayModal(true)
        }}
      >
        <BitcoinIcon className="mr-1" /> Save Now
      </Button>
    </div>
  )
}
