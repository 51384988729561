import { PriceSwitcher } from '@/components/PriceSwitcher'
import { AlertTitle } from '@/components/ui/alert'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { api } from '@/lib/api'
import { formatDateWithTime, formatToUsd } from '@/lib/formatting'
import { cn, getBlockUrl, getOrdinalExplorerUrl } from '@/lib/utils'
import { AppRoutes } from '@/routes'
import { IArchive, POW } from '@/types'
import {
  BitcoinIcon,
  CheckCircleIcon,
  ExternalLinkIcon,
  InfoIcon,
  LoaderCircleIcon,
  ZapIcon,
  PickaxeIcon,
  CuboidIcon,
  FingerprintIcon,
  CircleHelpIcon,
} from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  archive: IArchive
  openPaymentModal: () => void
}

export function OnChainDetails(props: Props) {
  const { archive, openPaymentModal } = props

  const { data: btcInfo } = api.bitcoin.priceInfo.useQuery()

  const hashOnlySaved = archive.hash_tx?.txid && !archive.full_text_tx?.txid

  return (
    <div className="flex items-center justify-between gap-2 rounded-md bg-gradient-to-br from-indigo-600 to-indigo-300 px-4 py-2 text-white dark:from-indigo-600/70 dark:to-indigo-300/70">
      <div className="flex items-center gap-2">
        <CheckCircleIcon className="size-4" />
        <div className="flex flex-col">
          <AlertTitle className="mb-0">Saved on Bitcoin</AlertTitle>
          {hashOnlySaved && (
            <div className="mt-2 text-sm">
              This content's hash is saved on Bitcoin{' '}
              <Link
                to={`${AppRoutes.DIGGING_DEEPER}#hashes`}
                target="_blank"
                className="inline underline underline-offset-4"
              >
                <CircleHelpIcon className="inline size-4" />
              </Link>{' '}
            </div>
          )}
        </div>
      </div>
      <Popover>
        <div className="flex flex-col">
          {hashOnlySaved ? (
            <Button
              className="mb-2"
              variant="default"
              size="sm"
              onClick={openPaymentModal}
            >
              <BitcoinIcon className="mr-1 h-4 w-4" /> Save full text
            </Button>
          ) : null}
          <PopoverTrigger asChild>
            <Button variant="secondary" size="sm">
              Show details
              <InfoIcon className="size-5" />
            </Button>
          </PopoverTrigger>
        </div>

        <PopoverContent
          className={cn(
            'grid w-full max-w-2xl grid-cols-1 gap-x-4 gap-y-2 font-mono text-xs sm:grid-cols-[auto,1fr]',
          )}
        >
          {!btcInfo ? (
            <div className="flex items-center justify-center">
              <LoaderCircleIcon className="h-4 w-4 animate-spin" />
            </div>
          ) : null}

          {archive.hash_tx && btcInfo && (
            <Details
              fullText={false}
              blockTimestamp={archive.hash_tx.block_timestamp}
              ordinalId={archive.hash_tx.ordinal_id}
              blockHeight={archive.hash_tx.block_height}
              blockHash={archive.hash_tx.block_hash}
              feeRate={archive.hash_tx.sats_per_byte}
              totalFeeSats={archive.hash_tx.total_sats}
              bitcoinPrice={btcInfo.price}
            />
          )}
          {archive.full_text_tx && btcInfo ? (
            <>
              {archive.hash_tx ? <div className="col-span-2 mt-4" /> : null}
              <Details
                fullText={true}
                blockTimestamp={archive.full_text_tx.block_timestamp}
                ordinalId={archive.full_text_tx.ordinal_id}
                blockHeight={archive.full_text_tx.block_height}
                blockHash={archive.full_text_tx.block_hash}
                feeRate={archive.full_text_tx.sats_per_byte}
                totalFeeSats={archive.full_text_tx.total_sats}
                bitcoinPrice={btcInfo.price}
                pow={archive.full_text_tx.pow}
              />
            </>
          ) : null}
        </PopoverContent>
      </Popover>
    </div>
  )
}

function Details(props: {
  fullText: boolean
  blockTimestamp: number
  ordinalId: string
  blockHeight: number
  blockHash: string
  /** Sats per byte */
  feeRate: number
  totalFeeSats: number
  bitcoinPrice: number
  pow?: POW
}) {
  const {
    fullText,
    blockTimestamp,
    ordinalId,
    blockHeight,
    blockHash,
    feeRate,
    bitcoinPrice,
    totalFeeSats,
    pow,
  } = props

  const { data: btcPrice } = api.bitcoin.priceInfo.useQuery()

  return (
    <>
      <div className="text-muted-foreground col-span-2">
        {fullText ? 'Full Text' : 'Hash Only'}
      </div>
      <div className="flex items-center gap-1.5 font-semibold">
        <FingerprintIcon className="size-4" /> Transaction:
      </div>
      <a
        href={getOrdinalExplorerUrl(ordinalId)}
        target="_blank"
        rel="noreferrer noopener"
        className="text-theme flex items-center gap-1 justify-self-start break-all text-xs underline-offset-4 hover:underline"
      >
        {ordinalId} <ExternalLinkIcon className="h-4 w-4 flex-shrink-0" />
      </a>

      <div className="col-span-2 mt-1">
        <div className="flex items-center gap-1.5 font-semibold">
          <CuboidIcon className="size-4" /> Block Info:
        </div>
      </div>
      <div className="flex items-center gap-1.5 pl-4 font-semibold">
        <CuboidIcon className="size-4 opacity-0" /> Timestamp:
      </div>
      <div>{formatDateWithTime(blockTimestamp * 1000)}</div>

      <div className="flex items-center gap-1.5 pl-4 font-semibold">
        <CuboidIcon className="size-4 opacity-0" /> Hash:
      </div>
      <div className="break-words">{blockHash}</div>
      <div className="flex items-center gap-1.5 pl-4 font-semibold">
        <CuboidIcon className="size-4 opacity-0" /> Height:
      </div>
      <a
        href={getBlockUrl(blockHeight)}
        target="_blank"
        rel="noreferrer noopener"
        className="text-theme flex items-center gap-1 justify-self-start break-all text-xs underline-offset-4 hover:underline"
      >
        {blockHeight} <ExternalLinkIcon className="h-4 w-4 flex-shrink-0" />
      </a>

      <div className="col-span-2 mt-1">
        <div className="flex items-center gap-1.5 font-semibold">
          <PickaxeIcon className="size-4" /> Mining Fees:
        </div>
      </div>
      <div className="flex items-center gap-1.5 pl-4 font-semibold">
        <PickaxeIcon className="size-4 opacity-0" /> Rate:
      </div>
      <div>{feeRate} sats/byte</div>
      <div className="flex items-center gap-1.5 pl-4 font-semibold">
        <PickaxeIcon className="size-4 opacity-0" /> Total Paid:
      </div>
      <PriceSwitcher
        sats={totalFeeSats}
        bitcoinPrice={bitcoinPrice}
        className="text-theme justify-self-start"
      />
      {pow && (
        <>
          <div className="col-span-2" />
          <div className="flex items-center gap-1.5 font-semibold">
            <ZapIcon className="h-4 w-4" /> Energy Usage:
          </div>
          <Dialog>
            <div>
              <DialogTrigger className="text-theme inline underline-offset-4 hover:underline">
                View
              </DialogTrigger>
            </div>
            <DialogContent className="max-w-2xl">
              <DialogHeader>
                <DialogTitle>Energy Usage</DialogTitle>
                <DialogDescription>
                  All information is estimated only and may not reflect actual
                  results. Mining efficiency and the energy cost basis are
                  assumptions based off average industry rates.
                </DialogDescription>
              </DialogHeader>

              <div className="mt-2 grid grid-cols-[auto,1fr] gap-x-6 gap-y-2 text-sm">
                {Object.entries(pow)
                  .filter(
                    ([key]) => typeof POW_MAP[key as keyof POW] !== 'undefined',
                  )
                  .map(([key, value]) => (
                    <React.Fragment key={key}>
                      <div className="break-all font-semibold">
                        {POW_MAP[key as keyof POW]}:
                      </div>
                      <div className="text-muted-foreground break-all">
                        {/* {typeof value === 'number'
                          ? value.toLocaleString()
                          : value} */}
                        {value}
                      </div>
                    </React.Fragment>
                  ))}

                <div className="break-all font-semibold">TX fees paid:</div>
                <div className="text-muted-foreground break-all">
                  {formatToUsd(pow.tx_fee_sats, btcPrice?.price ?? 0)}
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  )
}

const POW_MAP: Partial<Record<keyof POW, string>> = {
  assume_efficiency: 'Mining efficiency',
  assume_kwh_price: 'Energy cost basis',
  block_energy: 'Block energy consumption',
  block_energy_cost: 'Block energy cost',
  block_hash_rate: 'Block hash rate',
  tx_energy: 'TX energy consumption',
  tx_energy_cost: 'TX energy cost',
}
