import { cn } from '@/lib/utils'
import { useState } from 'react'

interface Props {
  values: (number | string)[]
  className?: string
}

/** On click, switch between the provided values */
export function DataSwitcher(props: Props) {
  const { values, className } = props

  const [idx, setIdx] = useState(0)

  const changeValue = () => {
    setIdx((idx + 1) % values.length)
  }

  return (
    <button
      type="button"
      onClick={changeValue}
      className={cn('inline-flex cursor-pointer', className)}
    >
      {values[idx]}
    </button>
  )
}
