import { useAudioPlayer } from '@/hooks/useAudioPlayer'
import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

/** The actual `<audio>` tag instance.
 * This should only be rendered once globally, regardless of the implementation of the audio player UI.
 */
export function AudioPlayerCore() {
  const playerRef = useRef<ReactPlayer>(null)

  const [ready, setReady] = useState(false)

  const {
    audioUrl,
    isPlaying,
    volume,
    progress,
    duration,
    playbackRate,
    updateState,
    title,
    artist,
    checkForSavedState,
    shouldUpdateSeek,
    handleSeek,
    setShouldUpdateSeek,
    onSeek,
  } = useAudioPlayer()

  useEffect(() => {
    onSeek((progress) => {
      playerRef.current?.seekTo(progress)
    })
  }, [])

  const handleProgress = (state: { played: number }) => {
    if (state.played) {
      updateState({ progress: state.played })
    }
  }

  const handleDuration = (duration: number) => {
    updateState({ duration })
  }

  // Add useEffect for MediaSession setup
  useEffect(() => {
    if ('mediaSession' in navigator && title) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title,
        artist: artist,
        artwork: [{ src: '/audio-artwork.png', type: 'image/png' }],
      })

      navigator.mediaSession.setActionHandler('play', () => {
        updateState({ isPlaying: true })
        playerRef.current?.getInternalPlayer()?.play()
      })

      navigator.mediaSession.setActionHandler('pause', () => {
        updateState({ isPlaying: false })
        playerRef.current?.getInternalPlayer()?.pause()
      })

      navigator.mediaSession.setActionHandler('seekto', (details) => {
        if (details.seekTime && playerRef.current) {
          const seekTime = details.seekTime
          const newProgress = seekTime / duration

          updateState({ progress: newProgress })
          playerRef.current.seekTo(newProgress)
        }
      })
    }
  }, [title, duration, artist])

  // Update playback state in MediaSession
  useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.playbackState = isPlaying ? 'playing' : 'paused'
    }
  }, [isPlaying])

  // Add position state updates
  useEffect(() => {
    if ('mediaSession' in navigator && duration) {
      navigator.mediaSession.setPositionState({
        duration: duration,
        playbackRate: playbackRate,
        position: progress * duration,
      })
    }
  }, [progress, duration, playbackRate])

  useEffect(() => {
    checkForSavedState()
  }, [ready])

  useEffect(() => {
    if (shouldUpdateSeek && ready && duration) {
      handleSeek(shouldUpdateSeek)

      setShouldUpdateSeek(0)
    }
  }, [shouldUpdateSeek, ready, duration])

  return (
    <ReactPlayer
      ref={playerRef}
      url={audioUrl ?? undefined}
      playing={isPlaying}
      volume={volume}
      height={0}
      width={0}
      onReady={() => setReady(true)}
      onEnded={() => updateState({ isPlaying: false })}
      onProgress={handleProgress}
      onDuration={handleDuration}
      playbackRate={playbackRate}
    />
  )
}
