import { formatDate } from '@/lib/formatting'
import { cn } from '@/lib/utils'

type Mark = { date: number | undefined; label: string }

interface Props {
  marks: Mark[]
}

const hasDate = (m: Mark): m is Mark & { date: number } => m.date !== undefined

export function VerticalTimeline(props: Props) {
  const marks = props.marks.filter(hasDate).sort((a, b) => a.date - b.date)
  return (
    <div className="relative mx-auto overflow-hidden px-4">
      {/* Vertical line */}
      <div className="absolute left-1/2 h-full w-0.5 -translate-x-1/2 transform bg-gray-200"></div>

      {marks.map((item, index) => (
        <div
          key={index}
          className={`relative ${cn({ 'mb-8': index !== marks.length - 1 })}`}
        >
          {/* Dot */}
          <div className="bg-theme absolute left-1/2 z-20 h-4 w-4 -translate-x-1/2 transform rounded-full border-4 border-white"></div>

          {/* Content */}
          <div
            className={`relative flex items-center ${index % 2 === 0 ? 'flex-row-reverse' : 'flex-row'}`}
          >
            {index === marks.length - 1 && (
              <div className="bg-background absolute left-1/2 top-0 z-10 h-full w-1 -translate-x-1/2" />
            )}
            <div
              className={`z-10 w-[60%] text-center ${index % 2 === 0 ? 'pl-8' : 'pr-6'}`}
            >
              <time className="text-xs font-semibold">{item.label}</time>
              <p className="text-muted-foreground mt-1 text-xs">
                {formatDate(item.date)}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
