import { Bitcoin } from 'backend/src/lib/bitcoin'

export function mhtmlToHtml(mhtmlContent: string) {
  // Split the MHTML content into parts by boundary
  const boundaryRegex = /boundary="([^"]+)"/
  const boundaryMatch = mhtmlContent.match(boundaryRegex)
  if (!boundaryMatch) {
    console.error('Could not find the boundary in the MHTML file.')
    return
  }
  const boundary = boundaryMatch[1]
  const parts = mhtmlContent.split(`--${boundary}`)

  // Find and extract the HTML part
  let htmlContent = ''
  parts.forEach((part) => {
    if (part.includes('Content-Type: text/html')) {
      const htmlStart = part.indexOf('<html')
      if (htmlStart !== -1) {
        htmlContent = part.substring(htmlStart).trim()
      }
    }
  })

  if (htmlContent) {
    return htmlContent
  } else {
    console.log('No HTML content found in the MHTML file.')
  }
}

export const formatDate = (date: number) =>
  new Intl.DateTimeFormat('en-US').format(date)

export const formatDateLong = (date: number) =>
  new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(date)

export const formatDateWithTime = (date: number) =>
  new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(date)

export const formatCurrency = (amount: number) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    amount,
  )

export const formatToUsd = (sats: number, btcPriceUsd: number) =>
  formatCurrency((sats / Bitcoin.SATS_PER_BITCOIN) * btcPriceUsd)

export const formatFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const compactNumber = (number: number | undefined) => {
  if (!number) return 0

  const formatter = new Intl.NumberFormat('en-US', { notation: 'compact' })

  return formatter.format(number)
}

const WATT_HOURS = {
  KWH: 1000,
  MWH: 1_000_000,
  GWH: 1_000_000_000,
  TWH: 1_000_000_000_000,
}

export const formatWattHours = (wattHours: number) => {
  if (wattHours < WATT_HOURS.KWH) {
    return `${wattHours.toLocaleString()} Wh`
  } else if (wattHours < WATT_HOURS.MWH) {
    return `${Number((wattHours / WATT_HOURS.KWH).toFixed()).toLocaleString()} kWh`
  } else if (wattHours < WATT_HOURS.GWH) {
    return `${Number((wattHours / WATT_HOURS.MWH).toFixed(2)).toLocaleString()} MWh`
  } else if (wattHours < WATT_HOURS.TWH) {
    return `${Number((wattHours / WATT_HOURS.GWH).toFixed(2)).toLocaleString()} GWh`
  } else {
    return `${Number((wattHours / WATT_HOURS.TWH).toFixed(2)).toLocaleString()} TWh`
  }
}
