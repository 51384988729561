import type React from 'react'

import { ComponentProps, useEffect, useMemo, useRef, useState } from 'react'

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import useBalance from '@/hooks/useBalance'
import { api } from '@/lib/api'
import { PAYMENT_PAYMAIL, SATS_PER_BITCOIN } from '@/lib/constants'
import { Env } from '@/lib/env'
import { formatCurrency } from '@/lib/formatting'
import { IArchive } from '@/types'
import { Wallet } from '@canonicxyz/wallet-sdk'
import * as bsv from 'bsv'
import { PayButton } from '../PayButton'
import useUser from '@/hooks/useUser'
import { Button } from '../ui/button'
import { Authentication } from '../Authentication'
import { DataSwitcher } from '../DataSwitcher'

const MAX_KWH = 1_000
/** USD */
const MAX_REWARD = 100

const STARTING_KWH = MAX_KWH / 2
const STARTING_REWARD = MAX_REWARD / 2

const STARTING_KWH_PERCENT = (STARTING_KWH / MAX_KWH) * 100
const STARTING_REWARD_PERCENT = (STARTING_REWARD / MAX_REWARD) * 100

interface Props {
  archive: IArchive
  onPayment: ({
    txid,
    ticker,
    estimatedFeeSats,
  }: {
    txid: string
    ticker: string
    estimatedFeeSats: number
  }) => void
}

export function UpvoteSelector(props: Props) {
  const { archive, onPayment } = props

  const [isDragging, setIsDragging] = useState(false)
  const [feesUsd, setFeesUsd] = useState(0)
  const [totalUsd, setTotalUsd] = useState(0)
  const [showLogin, setShowLogin] = useState(false)

  const { user } = useUser()
  const { allUtxos } = useBalance()

  const { data: btcInfo } = api.bitcoin.priceInfo.useQuery()

  const { data: powInfo } = api.bitcoin.estimatePowCost.useQuery(
    { satoshis: 1 },
    { placeholderData: (prev) => prev },
  )

  const minSatsPerByte =
    (btcInfo?.sats_per_kb_btc_mempool?.minimumFee ?? 4000) / 1000

  const utxoCount =
    user &&
    allUtxos &&
    (Env.PROD ? allUtxos?.BTC?.utxos : allUtxos?.SIGNET?.utxos)
      ? Env.PROD
        ? allUtxos?.BTC?.utxos.length
        : allUtxos?.SIGNET?.utxos.length
      : 0
  const minSats =
    (400 + Math.max(0, ((utxoCount ?? 0) - 1) * 150)) * minSatsPerByte

  const { data: powEstimateMin } = api.bitcoin.estimatePowCost.useQuery(
    {
      satoshis: minSats,
    },
    { placeholderData: (prev) => prev },
  )

  const [position, setPosition] = useState({
    x: STARTING_KWH_PERCENT,
    y: STARTING_REWARD_PERCENT,
  }) // Start at center

  /** Percentages between 1 and 100 */
  const [kwhPos, setKwhPos] = useState(STARTING_KWH_PERCENT)
  const [creatorRewardPos, setCreatorRewardPos] = useState(
    STARTING_REWARD_PERCENT,
  )

  const containerRef = useRef<HTMLDivElement>(null)
  const pointRef = useRef<HTMLDivElement>(null)
  const isDraggingRef = useRef(false)

  // Calculate the mining fee and creator reward based on position
  useEffect(() => {
    // Calculate percentages (0-100) based on position
    // Y position (left to right) determines mining fee
    // X position (bottom to top) determines creator reward
    setKwhPos(Math.round(100 - position.y))
    setCreatorRewardPos(Math.round(position.x))
  }, [position])

  // Handle mouse events for dragging
  const handleMouseDown = (e: React.MouseEvent) => {
    isDraggingRef.current = true
    updateMousePosition(e)
  }

  const handleMouseUp = (e: MouseEvent) => {
    isDraggingRef.current = false
  }

  const handleDocumentMouseMove = (e: MouseEvent) => {
    if (isDraggingRef.current) {
      setIsDragging(true)
      updatePosition(e.clientX, e.clientY)
    } else {
      setIsDragging(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp)
    document.addEventListener('mousemove', handleDocumentMouseMove)

    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('mousemove', handleDocumentMouseMove)
    }
  }, [])

  // Handle touch events for mobile
  const handleTouchStart = (e: React.TouchEvent) => {
    isDraggingRef.current = true
    updateTouchPosition(e)
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    if (isDraggingRef.current) {
      setIsDragging(true)
      updateTouchPosition(e)
    } else {
      setIsDragging(false)
    }
  }

  const handleTouchEnd = () => {
    isDraggingRef.current = false
  }

  const updateMousePosition = (e: React.MouseEvent) => {
    updatePosition(e.clientX, e.clientY)
  }

  // Update position based on touch coordinates
  const updateTouchPosition = (e: React.TouchEvent) => {
    if (e.touches[0]) {
      updatePosition(e.touches[0].clientX, e.touches[0].clientY)
    }
  }

  const updatePosition = (clientX: number, clientY: number) => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect()

      // Calculate position relative to top left of drag container
      const relX = clientX - containerRect.left
      const relY = clientY - containerRect.top

      let xPercent = (relX / containerRect.width) * 100

      let yPercent = (relY / containerRect.height) * 100

      // Clamp values from 0-100
      xPercent = Math.max(0, Math.min(100, xPercent))
      yPercent = Math.max(0, Math.min(100, yPercent))

      // "Snap" to axis lines
      if (xPercent > 48 && xPercent < 52) {
        xPercent = 50
      }

      if (yPercent > 48 && yPercent < 52) {
        yPercent = 50
      }

      setPosition({ x: xPercent, y: yPercent })
    }
  }

  const dustUsd = btcInfo?.dust_usd ?? 0

  let creatorRewardUsd = (creatorRewardPos / 100) * MAX_REWARD
  creatorRewardUsd = creatorRewardUsd < dustUsd ? 0 : creatorRewardUsd

  const kwh =
    (kwhPos / 100) * MAX_KWH + (powEstimateMin?.estimate_watt_hours ?? 0) / 1000

  const boostPercentage = (kwh / ((archive.total_watt_hours ?? 0) / 1000)) * 100

  const script = useMemo(() => {
    const ordinalId =
      archive.full_text_tx?.ordinal_id ?? archive.hash_tx?.ordinal_id

    const hashOnly = !!archive.hash_tx?.ordinal_id && !archive.full_text_tx

    if (!ordinalId && !hashOnly) return undefined

    const data = `ark:${ordinalId}${hashOnly ? `:${archive.hash_tx?.index || 0}` : ''}`
    const buf = Buffer.from(data)

    const script = bsv.Script.fromOpReturnData(buf)

    return script.toAsmString()
  }, [archive, allUtxos, btcInfo])

  // Using an address makes the TX building a lot faster since we don't have to
  // resolve the paymail on every change.
  const paymail = archive.user_pubkey
    ? `${archive.user_pubkey}@ark.page`
    : PAYMENT_PAYMAIL
  const [rewardDestination, setRewardDestination] = useState(paymail)

  useEffect(() => {
    const getAddress = async () => {
      const res = await Wallet.lookupPaymail(paymail)

      setRewardDestination(res.address)
    }

    getAddress()
  }, [paymail])

  const wh = kwh * 1000

  const feeSats = useMemo(() => {
    return Math.ceil(wh / (powInfo?.estimate_watt_hours ?? 0))
  }, [wh, powInfo])

  return (
    <Card className="max-w-2xl select-none rounded-none border-none sm:rounded-lg sm:border dark:bg-neutral-800">
      <CardHeader className="flex flex-col items-center pb-0 sm:pb-6 dark:bg-neutral-800">
        <CardTitle>Upvote</CardTitle>
        <CardDescription>Drag the point to adjust your upvote</CardDescription>
      </CardHeader>
      <CardContent className="mb-2 mt-4 flex flex-col items-center gap-12">
        <div className="flex w-full items-stretch justify-center gap-2">
          <div
            ref={containerRef}
            className="relative aspect-square w-full max-w-md touch-none"
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {/* Axes labels */}
            <div className="text-muted-foreground absolute inset-x-0 -bottom-7 select-none text-center text-xs uppercase">
              &larr; Reward &rarr;
            </div>
            <div className="absolute inset-y-0 -left-14 flex items-center">
              <div className="text-muted-foreground -rotate-90 transform select-none text-xs uppercase">
                &larr; Visibility &rarr;
              </div>
            </div>

            {/* Draggable container  */}
            <div className="border-border absolute inset-0 z-10 overflow-hidden border bg-neutral-100 dark:border-neutral-700 dark:bg-neutral-900">
              {/* Horizontal grid lines */}
              <div className="absolute inset-0 flex items-center">
                <div
                  className={`w-full border-t border-neutral-300 dark:border-neutral-700/50`}
                />
              </div>

              {/* Vertical grid lines */}
              <div className="absolute inset-0 flex justify-center">
                <div
                  className={`h-full border-l border-neutral-300 dark:border-neutral-700/50`}
                />
              </div>

              {/* Horizontal indicator line */}
              <div
                className="absolute h-0.5 bg-indigo-500/30 transition-all duration-500"
                style={{
                  left: '0%',
                  top: `${position.y}%`,
                  width: '100%',
                  transition: isDragging ? 'none' : undefined,
                }}
              />

              {/* Vertical indicator line */}
              <div
                className="absolute w-0.5 bg-indigo-500/30 transition-all duration-500"
                style={{
                  top: '0%',
                  left: `${position.x}%`,
                  height: '100%',
                  transition: isDragging ? 'none' : undefined,
                }}
              />
            </div>

            {/* Draggable point */}
            <div
              ref={pointRef}
              className="absolute z-10 flex h-6 w-6 -translate-x-1/2 -translate-y-1/2 transform cursor-grab items-center justify-center rounded-full border-2 border-white bg-indigo-500 shadow-lg transition-all duration-500 active:cursor-grabbing"
              style={{
                left: `${position.x}%`,
                top: `${position.y}%`,
                transition: isDragging ? 'none' : undefined,
              }}
            >
              <div className="h-2 w-2 rounded-full bg-white" />
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col items-center gap-6">
          <div className="border-border mt-auto w-full max-w-xs space-y-6 rounded-lg border bg-neutral-100 p-4 dark:border-neutral-700 dark:bg-neutral-900">
            <div className="flex flex-col items-center">
              <div className="text-muted-foreground text-xs uppercase tracking-wider">
                Visibility boost
              </div>
              <div className="text-2xl font-bold text-amber-500">
                +
                <DataSwitcher
                  values={[
                    `${kwh.toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                    })} kWh (${boostPercentage.toLocaleString(undefined, { maximumFractionDigits: boostPercentage > 100 ? 0 : 2 })}%)`,

                    `${feeSats / SATS_PER_BITCOIN} BTC`,
                    `${feeSats.toLocaleString()} sats`,
                  ]}
                />
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-muted-foreground text-xs uppercase tracking-wider">
                Creator reward
              </div>
              <div className="text-2xl font-bold text-amber-500">
                {formatCurrency(creatorRewardUsd)}
              </div>
            </div>
          </div>
          <div className="flex w-full flex-1 flex-col items-center justify-center gap-2">
            {user &&
            allUtxos &&
            (Env.PROD ? allUtxos?.BTC?.utxos : allUtxos?.SIGNET?.utxos) ? (
              <PayButton
                outputs={[
                  creatorRewardUsd
                    ? {
                        addressOrPaymail: rewardDestination,
                        amount: creatorRewardUsd,
                        currency: 'USD',
                      }
                    : undefined,
                  script ? { script, satoshis: 0 } : undefined,
                ]}
                feeSats={feeSats}
                ticker={!Env.PROD ? 'SIGNET' : 'BTC'}
                onFeesChange={setFeesUsd}
                disabled={!dustUsd || !script}
                onSuccess={({ txid, ticker }) =>
                  onPayment({
                    txid,
                    ticker,
                    estimatedFeeSats: Number(feeSats.toFixed(0)),
                  })
                }
                onTotalChange={(total) => {
                  setTotalUsd(total)
                }}
              />
            ) : !showLogin ? (
              <Button
                variant="theme"
                onClick={() => setShowLogin(true)}
                className="w-full max-w-xs"
              >
                Login
              </Button>
            ) : (
              <Authentication
                view="login"
                inline
                className="bg-background p-6"
                hideSubheader
                onSuccess={() => {
                  setShowLogin(false)
                }}
              />
            )}

            {totalUsd > 0 && feesUsd > 0 ? (
              <div className="text-sm text-neutral-400">
                {formatCurrency(totalUsd - feesUsd)} + {formatCurrency(feesUsd)}{' '}
                in fees
              </div>
            ) : (
              <div className="invisible text-sm text-neutral-400">
                placeholder
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
