import * as React from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'

import { cn } from '@/lib/utils'
import { cva, type VariantProps } from 'class-variance-authority'

const sliderVariants = cva('', {
  variants: {
    variant: {
      default: 'bg-primary border-primary',
      theme: 'bg-theme border-theme',
    },
    thumbVariant: {
      default: 'bg-primary border-primary',
      outline: 'bg-background border-2 border-primary',
    },
  },
  defaultVariants: { variant: 'default' },
})

interface Mark {
  value: number
  label?: string
}

interface Props extends VariantProps<typeof sliderVariants> {
  thumbClassName?: string
  marks?: Mark[]
}

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & Props
>(
  (
    { className, variant, thumbVariant, thumbClassName, marks = [], ...props },
    ref,
  ) => (
    <SliderPrimitive.Root
      ref={ref}
      className={cn(
        'relative flex w-full touch-none select-none items-center',
        { 'pb-6': marks.length > 0 },
        className,
      )}
      {...props}
    >
      <SliderPrimitive.Track className="bg-secondary relative h-2 w-full grow rounded-full">
        <SliderPrimitive.Range
          className={cn(
            'absolute h-full rounded-full',
            sliderVariants({ variant }),
          )}
        />
        {marks.map(({ value, label }) => {
          const percent =
            props.min === props.max
              ? 0
              : ((value - (props.min ?? 0)) /
                  ((props.max ?? 100) - (props.min ?? 0))) *
                100

          return (
            <div
              key={value}
              className={`${cn(
                'absolute top-1/2 -translate-x-1/2 -translate-y-1/2',
              )}`}
              style={{ left: `${percent}%` }}
            >
              <div
                className={cn(
                  'invisible h-4 w-[2px]',
                  {
                    // if the value is the same as the min or max, don't show the line
                    'visible w-0': percent > 0 && percent < 100,
                  },
                  sliderVariants({ variant }),
                )}
              />
              {label && (
                <div
                  className={`${cn(
                    'text-muted-foreground absolute mt-1 w-max -translate-x-1/2 text-center text-xs',

                    {
                      '-translate-x-0': percent === 0,
                      '-translate-x-full': percent === 100,
                    },
                  )}`}
                >
                  {label}
                </div>
              )}
            </div>
          )
        })}
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb
        className={cn(
          `border-border ring-offset-background focus-visible:ring-ring block size-5 rounded-full border transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50`,
          sliderVariants({ thumbVariant }),
          thumbClassName,
        )}
      />
    </SliderPrimitive.Root>
  ),
)
Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
