import { api } from '@/lib/api'
import { useEffect, useState } from 'react'
import { BookIcon, LoaderCircleIcon, SearchIcon } from 'lucide-react'
import { InView } from 'react-intersection-observer'
import { useSearchParams } from 'react-router-dom'
import { ArchiveCard } from '@/components/ArchiveCard'
import { ArchiveStatus, BrowseSort } from '@/types'
import { SiteFilter } from './components/SiteFilter'
import { Button } from '@/components/ui/button'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { cn } from '@/lib/utils'
import TwitterXIcon from '@/components/TwitterXIcon'
import { Search } from '@/components/Search'
import { LocalStorage } from '@/lib/local-storage'

interface Props {
  disableEndlessScroll?: boolean
  pageSize?: number
  hideViewToggle?: boolean
  hideFilters?: boolean
  disableViewUrlChanges?: boolean
}

export const FEED_SEARCH_PARAMS = { SITE: 'site', VIEW: 'view' } as const

export const FEED_VIEWS = {
  RANKED: 'top',
  FEED: 'latest',
  SAVED: 'saved',
} as const

export function FeedPage(props: Props) {
  const {
    disableEndlessScroll,
    pageSize,
    hideViewToggle,
    hideFilters,
    disableViewUrlChanges,
  } = props

  const { data: btcInfo } = api.bitcoin.priceInfo.useQuery()

  const [showSearch, setShowSearch] = useState(false)
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState<ArchiveStatus | undefined>('on-chain')
  const [sort, setSort] = useState<BrowseSort>('fee_desc')
  const [searchParams, setSearchParams] = useSearchParams()
  const viewParam =
    searchParams.get(FEED_SEARCH_PARAMS.VIEW) || FEED_VIEWS.RANKED
  const [view, setView] = useState(viewParam)

  const site = searchParams.get(FEED_SEARCH_PARAMS.SITE) || ''

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isPending } =
    api.archives.browse.useInfiniteQuery(
      {
        search,
        limit: pageSize,
        status: status || 'all',
        domain: site || undefined,
        sort,
      },
      { getNextPageParam: (lastPage) => lastPage.nextPage },
    )

  const setSite = (newDomain: string) => {
    setSearchParams((prev) => {
      if (newDomain) {
        prev.set(FEED_SEARCH_PARAMS.SITE, newDomain)
      } else {
        prev.delete(FEED_SEARCH_PARAMS.SITE)
      }
      return prev
    })
  }

  useEffect(() => {
    switch (view) {
      case FEED_VIEWS.RANKED:
        setSort('fee_desc')
        setStatus('on-chain')
        break
      case FEED_VIEWS.FEED:
        setSort('date_desc')
        setStatus('all')
        break
      case FEED_VIEWS.SAVED:
        setSort('date_desc')
        setStatus('saved')
        break
    }

    if (!disableViewUrlChanges) {
      setSearchParams((prev) => {
        if (view === FEED_VIEWS.RANKED) {
          prev.delete(FEED_SEARCH_PARAMS.VIEW)
        } else {
          prev.set(FEED_SEARCH_PARAMS.VIEW, view)
        }
        return prev
      })
    }
  }, [view])

  const isEmpty =
    !isPending && (!data?.pages.length || !data?.pages[0].archives.length)

  const archives = data?.pages.flatMap((page) => page.archives)
  return (
    <div className={`mx-auto w-full max-w-screen-lg pt-2 sm:pt-8 md:pl-10`}>
      <div
        className={`flex flex-col items-center justify-between gap-4 transition-opacity duration-500 sm:flex-row sm:gap-2 ${cn(
          { 'invisible opacity-0': hideViewToggle },
        )}`}
      >
        <Tabs
          defaultValue={view}
          onValueChange={setView}
          className={`w-full sm:w-auto`}
        >
          <TabsList className="w-full">
            <TabsTrigger className="flex-1" value={FEED_VIEWS.RANKED}>
              Top
            </TabsTrigger>
            <TabsTrigger className="flex-1" value={FEED_VIEWS.FEED}>
              Latest
            </TabsTrigger>
            {!!LocalStorage.getAuthToken() && (
              <TabsTrigger className="flex-1" value={FEED_VIEWS.SAVED}>
                Saved
              </TabsTrigger>
            )}
          </TabsList>
        </Tabs>
        {!hideFilters && (
          <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
            <div className="grid w-full grid-cols-2 items-center gap-2 sm:grid-cols-[auto,auto,auto,auto,1fr]">
              <Button
                variant="ghost"
                className="hidden sm:flex"
                size="icon"
                onClick={() => setShowSearch(!showSearch)}
              >
                <SearchIcon className="size-5" />
              </Button>
              <Button
                variant={site === 'x.com' ? 'default' : 'outline'}
                onClick={() => setSite(site === 'x.com' ? '' : 'x.com')}
              >
                <TwitterXIcon className="mr-2 h-4 w-4" />
                X/Twitter
              </Button>
              <Button
                variant={site === 'gutenberg.org' ? 'default' : 'outline'}
                onClick={() =>
                  setSite(site === 'gutenberg.org' ? '' : 'gutenberg.org')
                }
              >
                <BookIcon className="mr-2 h-4 w-4 flex-shrink-0" />
                Project Gutenberg
              </Button>
              <SiteFilter site={site} setSite={setSite} status={status} />
              <Button
                variant="ghost"
                className="flex sm:hidden"
                size="icon"
                onClick={() => setShowSearch(!showSearch)}
              >
                <SearchIcon className="size-5" />
              </Button>
            </div>
          </div>
        )}
      </div>
      {showSearch && (
        <div className="mt-4">
          <Search onSearch={setSearch} />
        </div>
      )}
      <section className="mt-8 flex flex-col gap-2">
        {isPending &&
          new Array(pageSize ?? 12)
            .fill(0)
            .map((_, i) => (
              <div
                key={i}
                className="bg-muted mt-2 h-36 w-full animate-pulse rounded-lg"
              />
            ))}
        {isEmpty && (
          <div className="mt-6 text-center sm:text-left">
            No archives found.
          </div>
        )}

        {archives?.map((a, i) => (
          <ArchiveCard
            key={a._id}
            archive={a}
            btcPriceUsd={btcInfo?.price ?? 0}
          />
        ))}
        {!disableEndlessScroll && hasNextPage && (
          <InView
            as="div"
            onChange={(inView) => {
              if (inView) {
                fetchNextPage()
              }
            }}
          />
        )}
      </section>
      {isFetchingNextPage && (
        <div className="mt-6 flex w-full justify-center">
          <LoaderCircleIcon className="animate-spin" />
        </div>
      )}
    </div>
  )
}
