import { createTRPCReact } from '@trpc/react-query'
import type { AppRouter } from 'backend/src/routers/app.router'

export const uploadFiles = async (files: File[]) => {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files', file)
  })

  const res = await fetch(`/api/files/upload`, {
    method: 'POST',
    body: formData,
  })

  if (!res.ok) {
    throw new Error('Failed to upload files')
  }

  const data = (await res.json()) as { urls: string[] }

  return data
}

/** TRPC React client */
export const api = createTRPCReact<AppRouter>()

export const apiExtra = {
  uploadFiles,
}
