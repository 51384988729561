import { Button } from '@/components/ui/button'
import { PlayCircleIcon } from 'lucide-react'
import { AppRoutes } from '@/routes'
import { IArchive } from '@/types'
import { useAudioPlayer } from '@/hooks/useAudioPlayer'
import { api } from '@/lib/api'

interface Props {
  archive: IArchive
}

export function AudioButton(props: Props) {
  const { archive } = props

  const audio = useAudioPlayer()

  const enableTtsQuery =
    archive &&
    'url' in archive &&
    archive.format !== 'mhtml' &&
    archive.format !== 'media' &&
    !archive.tts_url

  const { data: ttsStatus } = api.archives.ttsStatus.useQuery(
    { url: archive.url },
    { enabled: enableTtsQuery },
  )

  const ttsUrl = archive.tts_url
    ? archive.tts_url
    : archive.format !== 'mhtml' && ttsStatus?.status === 'completed'
      ? ttsStatus.url
      : undefined

  if (archive.format === 'media') return null
  return (
    <>
      {ttsUrl && (
        <Button
          variant="outline"
          className="mt-4 w-full"
          onClick={() => {
            audio.playUrl(ttsUrl, {
              title: archive.title ?? new URL(archive.url).hostname,
              artist: archive.author ?? archive.url,
              archiveUrl: archive.url,
              titleLink: AppRoutes.buildArchiveRoute(archive.url),
            })
          }}
        >
          Listen
          <PlayCircleIcon className="size-5" />
        </Button>
      )}
      {!ttsUrl && ttsStatus?.status === 'queued' ? (
        <div className="bg-background mt-4 w-full rounded-lg border p-4 text-center text-sm shadow-sm">
          Audio is being generated, please check back soon to listen. Estimated
          completion time: {ttsStatus.time_left}
        </div>
      ) : null}
    </>
  )
}
