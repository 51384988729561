import PayPlugin from '@/components/PayPlugin'
import { useResetWindowScroll } from '@/hooks/useResetWindowScroll'
import { EMAILS, PAYMENT_PAYMAIL } from '@/lib/constants'
import { AppRoutes } from '@/routes'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export function AboutPage() {
  useResetWindowScroll()

  const [sessionId] = useState(() => `${+new Date()}`)

  return (
    <div className="mt-10 flex flex-col items-center sm:mt-16">
      <section className="prose dark:prose-invert max-w-prose">
        <h1>Why Ark?</h1>
        <p>Ark preserves webpages... forever.</p>
        <p>
          We rely on the internet for so much information, yet websites shut
          down over time, their webpages get edited -- or worse, even hacked or
          deleted. Ark protects your most important public data by encoding it
          directly into the Bitcoin blockchain, where it's permanently archived,
          freely-accessible, and protected from tampering and censorship.
        </p>
        <p>
          You simply tell us what webpage or text content you want to preserve.
          Ark will save the webpage on our servers. Once you've paid the
          required transaction fees, Ark executes a Bitcoin transaction which
          permanently and irrevocably embeds your content into the blockchain,
          where nobody can change it, censor it, or remove it... forever.
        </p>

        <ul>
          <li>
            Fidelity - Preserve a news article before it is edited or removed
            without explanation
          </li>
          <li>
            Decentralization - Archive webpages without relying on a single
            provider. See{' '}
            <a
              href="https://www.bleepingcomputer.com/news/security/internet-archive-hacked-data-breach-impacts-31-million-users/"
              target="_blank"
              rel="noreferrer"
            >
              Internet Archive hacked, data breach impacts 31 million users
            </a>
          </li>
          <li>
            Transparency - Limit censorship by embedding your data in a free and
            open-source protocol
          </li>
          <li>
            Distributed - Ensure access to content across tens of thousands of
            computers
          </li>
        </ul>
        <p>
          Want more details about how we work? Check out our{' '}
          <Link to={AppRoutes.DIGGING_DEEPER}>Digging Deeper</Link> page.
        </p>
        <p>
          Contact us at{' '}
          <a href={`mailto:${EMAILS.CONTACT}`}>{EMAILS.CONTACT}</a>.
        </p>
      </section>
      <section className="mt-4">
        <div className="mt-12 flex justify-center">
          <PayPlugin
            paymail={PAYMENT_PAYMAIL}
            sessionId={sessionId}
            productName={'Ark Donation'}
            receiptEmail={EMAILS.RECEIPT}
            callbackUrl="https://pay.canonic.xyz/api/callback"
            title="Support Us"
          />
        </div>
      </section>
    </div>
  )
}
