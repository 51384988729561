import { cn } from '@/lib/utils'
import { BitcoinIcon, ArchiveIcon, BookmarkIcon } from 'lucide-react'
import React from 'react'

interface Props {
  className?: string
}

export function LoginBenefits(props: Props) {
  const { className } = props

  return (
    <div className={cn('space-y-3', className)}>
      <BenefitItem
        icon={<BitcoinIcon className="h-5 w-5 text-green-500" />}
        title="Get paid for your archives"
        description="Earn bitcoin for your content"
      />
      <BenefitItem
        icon={<ArchiveIcon className="h-5 w-5 text-blue-500" />}
        title="Easy on-chain archiving"
        description="Seamlessly save and secure your archives on the blockchain"
      />
      <BenefitItem
        icon={<BookmarkIcon className="h-5 w-5 text-purple-500" />}
        title="Bookmark and comment"
        description="Save your favorite archives and leave comments"
      />
    </div>
  )
}

interface BenefitItemProps {
  icon: React.ReactNode
  title: string
  description: string
}

function BenefitItem({ icon, title, description }: BenefitItemProps) {
  return (
    <div className="flex items-start space-x-3">
      <div className="mt-0.5">{icon}</div>
      <div>
        <h4 className="text-sm font-medium">{title}</h4>
        <p className="text-muted-foreground text-xs">{description}</p>
      </div>
    </div>
  )
}
