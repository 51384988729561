import { DataSwitcher } from '@/components/DataSwitcher'
import { Badge } from '@/components/ui/badge'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { ChartContainer, ChartTooltip } from '@/components/ui/chart'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { api } from '@/lib/api'
import { formatDate, formatToUsd, formatWattHours } from '@/lib/formatting'
import { getTxUrl } from '@/lib/utils'
import { IArchive, IUpvote } from '@/types'
import { Ticker, Wallet } from '@canonicxyz/wallet-sdk'
import { useMemo } from 'react'
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts'

const TABS = {
  LIST: 'list',
  GRAPH: 'graph',
}

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  archive: IArchive
}

const groupByDate = (archive: IArchive, upvotes: IUpvote[]) => {
  const grouped: { date: string; wh: number }[] = []

  const fullTextTx = archive.full_text_tx
  if (fullTextTx?.pow) {
    const date = new Date(
      fullTextTx.block_timestamp * 1000,
    ).toLocaleDateString()
    const wh = fullTextTx.pow.tx_watt_hours
    grouped.push({ date, wh })
  }

  const hashTx = archive.hash_tx
  if (hashTx?.pow) {
    const date = new Date(hashTx.block_timestamp * 1000).toLocaleDateString()
    const wh = hashTx.pow.tx_watt_hours

    const existing = grouped.find((g) => g.date === date)
    if (existing) {
      existing.wh += wh
    } else {
      grouped.push({ date, wh })
    }
  }

  for (const upvote of upvotes) {
    const date = new Date(upvote.created_at).toLocaleDateString()
    const wh = upvote.pow?.tx_watt_hours ?? upvote.pending?.watt_hours ?? 0

    const existing = grouped.find((g) => g.date === date)
    if (existing) {
      existing.wh += wh
    } else {
      grouped.push({ date, wh })
    }
  }

  return grouped
}

const getTotalSats = (archive: IArchive, upvotes: IUpvote[]) => {
  return (
    upvotes.reduce(
      (acc, upvote) =>
        acc + (upvote.pow?.tx_fee_sats ?? upvote.pending?.fee_sats ?? 0),
      0,
    ) +
    (archive.hash_tx?.total_sats ?? 0) +
    (archive.full_text_tx?.total_sats ?? 0)
  )
}

export function StatsModal(props: Props) {
  const { open, setOpen, archive } = props

  const { data: btcInfo } = api.bitcoin.priceInfo.useQuery()
  const { data: upvotes } = api.archives.allUpvotes.useQuery({
    archiveId: archive._id,
  })

  const chartData = useMemo(() => {
    if (!upvotes) return []
    return groupByDate(archive, upvotes)
  }, [upvotes, archive])

  const totalSats = useMemo(() => {
    if (!upvotes) return 0
    return getTotalSats(archive, upvotes)
  }, [upvotes])
  const totalBitcoin = `${(totalSats / Wallet.SATS_PER_BITCOIN).toFixed(6)} BTC`
  const totalUsd = formatToUsd(totalSats, btcInfo?.price ?? 0)

  const upvotesDesc = useMemo(() => {
    if (!upvotes) return []
    const upvotesDesc = [...upvotes]
    upvotesDesc.sort(
      (a, b) => +new Date(b.created_at) - +new Date(a.created_at),
    )
    return upvotesDesc
  }, [upvotes])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-screen-lg" aria-describedby={undefined}>
        <DialogHeader>
          <DialogTitle>
            {archive.title ?? new URL(archive.url).hostname}
          </DialogTitle>
        </DialogHeader>

        <div className="mt-2 flex flex-col gap-4 overflow-x-hidden">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            <Card>
              <CardHeader className="pb-2">
                <CardTitle>Energy</CardTitle>
                <CardDescription>
                  Total energy spent by saving on chain & upvoting
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="inline-flex text-3xl font-bold">
                  <DataSwitcher
                    values={[
                      `${(
                        (archive.total_watt_hours ?? 0) / 1000
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })} kWh`,
                      formatWattHours(archive.total_watt_hours ?? 0),
                      `${(archive.total_watt_hours ?? 0).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 0,
                        },
                      )} Wh`,
                    ]}
                  />
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="pb-2">
                <CardTitle>Value</CardTitle>
                <CardDescription>
                  Total value of all transactions related to this archive
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="inline-flex text-3xl font-bold">
                  <DataSwitcher
                    values={[
                      totalBitcoin,
                      totalUsd,
                      `${totalSats.toLocaleString()} sats`,
                    ]}
                  />
                </div>
              </CardContent>
            </Card>
          </div>

          <Tabs defaultValue={TABS.LIST}>
            <TabsList>
              <TabsTrigger value={TABS.LIST}>History</TabsTrigger>
              <TabsTrigger value={TABS.GRAPH}>Trends</TabsTrigger>
            </TabsList>

            <TabsContent value={TABS.GRAPH}>
              <Card>
                <CardContent className="mt-2 max-w-full overflow-x-auto">
                  <ChartContainer config={{}} className="min-h-[400px]">
                    <LineChart data={chartData} className="mt-2">
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="date"
                        tickFormatter={(value) => {
                          return new Date(value).toLocaleDateString()
                        }}
                      />
                      <YAxis
                        tickFormatter={(value) => formatWattHours(value)}
                        tick={{ width: 80 }}
                      />
                      <ChartTooltip
                        formatter={(value) => formatWattHours(Number(value))}
                        labelFormatter={(value) => {
                          return new Date(value).toLocaleDateString()
                        }}
                      />
                      <Line
                        type="monotone"
                        dataKey="wh"
                        className="stroke-theme"
                        name="Energy"
                        strokeWidth={2}
                        dot={{ r: 4 }}
                        activeDot={{ r: 6 }}
                      />
                    </LineChart>
                  </ChartContainer>
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value={TABS.LIST}>
              <Card>
                <CardContent className="p-0">
                  <Table>
                    <TableHeader className="bg-muted/50">
                      <TableRow>
                        <TableHead>Energy</TableHead>
                        <TableHead>Cost</TableHead>
                        <TableHead>Date</TableHead>
                        <TableHead></TableHead>
                        <TableHead>TX</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {upvotesDesc.map((upvote) => (
                        <UpvoteRow
                          key={upvote._id}
                          wattHours={
                            upvote.pow?.tx_watt_hours ??
                            upvote.pending?.watt_hours ??
                            0
                          }
                          txCost={
                            Wallet.formatDollars(
                              upvote.pow?.tx_fee_sats ??
                                upvote.pending?.fee_sats ??
                                0,
                              btcInfo?.price ?? 0,
                            ).string
                          }
                          date={+new Date(upvote.created_at)}
                          txid={upvote.txid}
                          ticker={upvote.ticker}
                        />
                      ))}
                      {archive.hash_tx?.pow && (
                        <UpvoteRow
                          wattHours={archive.hash_tx.pow.tx_watt_hours}
                          txCost={
                            Wallet.formatDollars(
                              archive.hash_tx.pow.tx_fee_sats,
                              btcInfo?.price ?? 0,
                            ).string
                          }
                          date={archive.hash_tx.block_timestamp * 1000}
                          txid={archive.hash_tx.txid}
                          ticker={archive.hash_tx.pow.ticker as Ticker}
                          genesis={!!!archive.full_text_tx}
                        />
                      )}
                      {archive.full_text_tx?.pow && (
                        <UpvoteRow
                          wattHours={archive.full_text_tx.pow.tx_watt_hours}
                          txCost={
                            Wallet.formatDollars(
                              archive.full_text_tx.pow.tx_fee_sats,
                              btcInfo?.price ?? 0,
                            ).string
                          }
                          date={archive.full_text_tx.block_timestamp * 1000}
                          txid={archive.full_text_tx.txid}
                          ticker={archive.full_text_tx.pow.ticker as Ticker}
                          genesis
                        />
                      )}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </div>
      </DialogContent>
    </Dialog>
  )
}

function UpvoteRow({
  wattHours,
  txCost,
  date,
  txid,
  ticker,
  genesis,
}: {
  wattHours: number
  txCost: string
  date: number
  txid: string
  ticker: Ticker
  genesis?: boolean
}) {
  return (
    <TableRow>
      <TableCell>{formatWattHours(wattHours)}</TableCell>
      <TableCell>{txCost}</TableCell>
      <TableCell>{formatDate(date)}</TableCell>
      <TableCell>{genesis ? <Badge>Genesis</Badge> : ''}</TableCell>
      <TableCell>
        <a
          href={getTxUrl(txid, ticker)}
          target="_blank"
          rel="noreferrer"
          className="text-theme text-xs underline-offset-4 hover:underline"
        >
          {txid}
        </a>
      </TableCell>
    </TableRow>
  )
}
