import { Output, Ticker, Wallet } from '@canonicxyz/wallet-sdk'
import { Button } from './ui/button'
import { useEffect, useState } from 'react'
import { BitcoinIcon, Loader2Icon } from 'lucide-react'
import { toast } from './ui/use-toast'
import useBalance from '@/hooks/useBalance'
import { ToastAction } from './ui/toast'
import { cn, getTxUrl } from '@/lib/utils'
import { formatCurrency } from '@/lib/formatting'

interface Props {
  outputs: Output[]
  ticker: Ticker
  label?: string
  disabled?: boolean
  satsPerByte?: number
  feeSats?: number
  showBalance?: boolean
  /** The class name for the button */
  className?: string
  /** The class name for the container */
  containerClassName?: string
  onFeesChange?: (usd: number) => void
  onTotalChange?: (usd: number) => void
  onSuccess?: ({
    ticker,
    txid,
    tx,
  }: {
    ticker: Ticker
    txid: string
    tx: string
  }) => void
}

let timeout: NodeJS.Timeout | null = null
let confirmationTimeout: NodeJS.Timeout | null = null

const DEBOUNCE_INTERVAL = 250
const CONFIRMATION_TIMEOUT = 5_000

export function PayButton(props: Props) {
  const {
    outputs,
    ticker,
    disabled,
    label,
    satsPerByte,
    feeSats,
    showBalance,
    className,
    containerClassName,
    onFeesChange,
    onTotalChange,
    onSuccess,
  } = props

  const { balance, allUtxos } = useBalance()

  const [totalUsd, setTotalUsd] = useState<number | null>(null)
  const [tx, setTx] = useState<string | null>(null)
  const [error, setError] = useState<string | null>()
  const [loading, setLoading] = useState(true)
  const [broadcasting, setBroadcasting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isWaitingForConfirmation, setIsWaitingForConfirmation] =
    useState(false)

  useEffect(() => {
    const build = async () => {
      try {
        setError(null)
        setLoading(true)

        const res = await Wallet.buildTx({
          ticker,
          outputs,
          customSatsPerByte: satsPerByte,
          feeSats,
        })
        // console.log(res.tx)

        // console.log(res)
        setTotalUsd(res.totalUsd)
        onFeesChange?.(res.fees.usd)
        onTotalChange?.(res.totalUsd)
        setTx(res.tx)
      } catch (err) {
        setTotalUsd(null)
        onFeesChange?.(0)
        onTotalChange?.(0)
        setTx(null)

        if (err instanceof Error) {
          setError(err.message)
        } else {
          setError('Error')
        }
      } finally {
        setLoading(false)
      }
    }

    if (disabled) {
      setError(null)
    }

    if (balance && !disabled) {
      timeout && clearTimeout(timeout)
      timeout = setTimeout(build, DEBOUNCE_INTERVAL)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [outputs, balance, disabled, ticker])

  const broadcast = async () => {
    if (!tx) return toast({ title: 'Missing TX', variant: 'destructive' })

    try {
      setBroadcasting(true)

      const { txid } = await Wallet.broadcastTx(ticker, tx)

      toast({
        title: 'Payment Successful',
        action: (
          <ToastAction altText="View" asChild>
            <a href={getTxUrl(txid, ticker)} target="_blank" rel="noreferrer">
              View
            </a>
          </ToastAction>
        ),
        variant: 'default',
        duration: 7_000,
      })

      setSuccess(true)

      setTimeout(() => {
        setSuccess(false)
      }, 3500)

      onSuccess?.({ ticker, txid, tx })

      setTx(null)
      setTotalUsd(null)
      onTotalChange?.(0)
      onFeesChange?.(0)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      } else {
        setError('Error')
      }
    } finally {
      setBroadcasting(false)
    }
  }

  const onClick = async () => {
    if (isWaitingForConfirmation) {
      setIsWaitingForConfirmation(false)
      await broadcast()
    } else {
      setIsWaitingForConfirmation(true)
    }
  }

  useEffect(() => {
    if (!isWaitingForConfirmation) return

    confirmationTimeout && clearTimeout(confirmationTimeout)
    confirmationTimeout = setTimeout(() => {
      setIsWaitingForConfirmation(false)
    }, CONFIRMATION_TIMEOUT)
  }, [isWaitingForConfirmation])

  return (
    <div className={cn('text-center', containerClassName)}>
      <Button
        variant={
          error ? 'destructive' : isWaitingForConfirmation ? 'warning' : 'theme'
        }
        disabled={!tx || disabled || loading || !!error || broadcasting}
        onClick={onClick}
        className={cn(
          'inline-grid h-auto grid-cols-[auto,1fr,auto] place-items-center whitespace-normal py-3',
          className,
        )}
      >
        <BitcoinIcon className="size-5" />

        {/* We do a grid layout with visiblity toggling so that the button width
        doesn't jump when moving between states. This means the button width will
        always be as wide as the widest state. */}
        <div
          className={cn(
            'invisible col-start-2 row-start-1 flex items-center gap-2',
            { visible: isWaitingForConfirmation },
          )}
        >
          Click again to confirm <Loader2Icon className="size-4 animate-spin" />
        </div>

        <div
          className={cn('invisible col-start-2 row-start-1 flex items-center', {
            visible: disabled,
          })}
        >
          {label || 'Pay'}
        </div>

        <div
          className={cn('invisible col-start-2 row-start-1 flex items-center', {
            visible: success,
          })}
        >
          Success
        </div>
        <div
          className={cn('invisible col-start-2 row-start-1 flex items-center', {
            visible: !!error,
          })}
        >
          {error}
        </div>

        <div
          className={cn(
            'invisible col-start-2 row-start-1 flex items-center gap-2',
            { visible: loading && !disabled },
          )}
        >
          Loading <Loader2Icon className="size-4 animate-spin" />
        </div>

        <div
          className={cn(
            'invisible col-start-2 row-start-1 flex items-center gap-2',
            { visible: broadcasting },
          )}
        >
          Sending <Loader2Icon className="size-4 animate-spin" />
        </div>

        <div
          className={cn('invisible col-start-2 row-start-1 flex items-center', {
            visible:
              !isWaitingForConfirmation &&
              !disabled &&
              !success &&
              !error &&
              !loading &&
              !broadcasting &&
              totalUsd,
          })}
        >
          {label ?? `Pay ${formatCurrency(totalUsd ?? 0)} with ${ticker}`}
        </div>
        <div className="w-5" />
      </Button>
      {showBalance && (
        <div className="text-muted-foreground mt-1 text-center text-sm">
          Balance: {formatCurrency(allUtxos?.[ticker]?.total_usd ?? 0)}
        </div>
      )}
    </div>
  )
}
