import React from 'react'
import { MediaPreview } from './MediaPreview'
import { Link } from 'react-router-dom'
import { MarkdownView } from '@/components/MarkdownView'
import { convertLinksToAnchors } from '@/lib/text'
import { IArchive } from '@/types'
import { FileIcon, ArrowRightIcon } from 'lucide-react'
import { cn } from '@/lib/utils'

interface Props {
  archive: IArchive
  iframeRef: React.RefObject<HTMLIFrameElement>
  iframeSrc: string | undefined
  className?: string
}

export function ArchiveContent(props: Props) {
  const { archive, iframeRef, iframeSrc, className } = props

  return (
    <section
      className={cn(
        'font-serif-body relative mx-auto max-w-prose text-lg',
        className,
      )}
    >
      {archive.format === 'markdown' && (
        <MarkdownView content={archive.text_content} />
      )}

      {archive.format === 'ascii-art' && (
        <div
          style={{ fontFamily: 'monospace' }}
          className="w-full whitespace-pre-wrap [word-break:break-word]"
        >
          {archive.text_content}
        </div>
      )}
      {archive.format === 'plain-text' && (
        <div>
          <div className="w-full whitespace-pre-wrap [word-break:break-word]">
            {convertLinksToAnchors(archive.text_content)}
          </div>
        </div>
      )}
      {archive.format === 'mhtml' && (
        <iframe
          ref={iframeRef}
          srcDoc={iframeSrc}
          className="w-full overflow-hidden"
        />
      )}
      {archive.format === 'media' && archive.media?.length && (
        <div className="prose dark:prose-invert prose-lg">
          {archive.media.map((media) =>
            media.mime_type.startsWith('image/') ? (
              <img key={media.url} src={media.url} alt={archive.title} />
            ) : (
              <Link
                key={media.url}
                to={media.url}
                target="_blank"
                className="bg-muted text-muted-foreground flex w-full items-center gap-2 rounded-md px-4 py-1 font-sans no-underline"
              >
                <FileIcon className="size-6" />
                <p className="text-sm">
                  View{' '}
                  <span className="uppercase">
                    {media.mime_type.split('/')[1].split('+')[0]}
                  </span>
                </p>
                <ArrowRightIcon className="ml-auto size-5" />
              </Link>
            ),
          )}
        </div>
      )}
      {archive.format !== 'media' && archive.media?.length ? (
        <div className="mt-10">
          <h3 className="mb-2 font-bold">Media</h3>
          <div className="flex flex-wrap gap-4">
            {archive.media.map((media) => (
              <MediaPreview key={media.url} media={media} />
            ))}
          </div>
        </div>
      ) : null}
    </section>
  )
}
