import { api } from '@/lib/api'
import { cn } from '@/lib/utils'
import { IArchive } from '@/types'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { toast } from '../ui/use-toast'
import { compactNumber, formatWattHours } from '@/lib/formatting'
import { ArrowUpIcon, ChevronUpIcon } from 'lucide-react'
import { Button } from '../ui/button'
import { TooltipContent, TooltipTrigger } from '../ui/tooltip'
import { Tooltip } from '../ui/tooltip'
import { UpvoteSelector } from './UpvoteSelector'
import { useState } from 'react'

interface Props {
  archive: IArchive
  showInKwh?: boolean
}

export function UpvoteButton(props: Props) {
  const { archive, showInKwh } = props

  const [open, setOpen] = useState(false)
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false)

  const utils = api.useUtils()

  const upvoteMutation = api.archives.upvote.useMutation({
    onError: (error) => {
      toast({ title: 'Error', description: error.message, duration: Infinity })
    },

    onSuccess: async () => {
      await handleUpvoteReceived()
    },
  })

  const handleUpvoteReceived = async () => {
    setOpen(false)
    setShowSuccessAnimation(true)

    setTimeout(async () => {
      await utils.archives.view.refetch({ url: archive.url })
      utils.archives.invalidate()

      setTimeout(() => {
        setShowSuccessAnimation(false)
      }, 1500)
    }, 3500)
  }

  const onPayment = ({
    ticker,
    txid,
    estimatedFeeSats,
  }: {
    ticker: string
    txid: string
    estimatedFeeSats: number
  }) => {
    if (ticker !== 'BTC' && ticker !== 'SIGNET') {
      toast({
        title: 'Error',
        description: 'Invalid ticker',
        duration: Infinity,
      })
      console.error('Invalid ticker', ticker)
      return
    }

    upvoteMutation.mutate({
      archiveId: archive._id,
      txid: txid,
      ticker: ticker,
      estimatedFeeSats,
    })
  }

  const onChain = !!(archive.full_text_tx?.ordinal_id || archive.hash_tx?.txid)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Tooltip>
        <TooltipTrigger asChild>
          <DialogTrigger asChild>
            <>
              <Button
                variant="theme"
                size="icon"
                className="flex h-8 w-auto gap-1 px-3 text-xs md:hidden"
                onClick={() => setOpen(true)}
              >
                <ArrowUpIcon className="size-5" />
                <div>{formatWattHours(archive.total_watt_hours ?? 0)}</div>
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setOpen(true)}
                className={`${cn('hidden h-auto flex-col items-center md:flex', { 'text-theme': showSuccessAnimation })}`}
              >
                <ChevronUpIcon
                  className={cn('size-6 transition-all', {
                    'animate-bounce': showSuccessAnimation,
                  })}
                />
                <div
                  className={cn('text-muted-foreground text-xs', {
                    'text-theme': showSuccessAnimation,
                  })}
                >
                  {showInKwh ? (
                    <>
                      {compactNumber((archive.total_watt_hours ?? 0) / 1000)}
                      <br />
                      kWh
                    </>
                  ) : (
                    <>
                      {
                        formatWattHours(archive.total_watt_hours ?? 0).split(
                          ' ',
                        )[0]
                      }
                      <br />
                      {
                        formatWattHours(archive.total_watt_hours ?? 0).split(
                          ' ',
                        )[1]
                      }
                    </>
                  )}
                </div>
              </Button>
            </>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent className="text-xs">
          {((archive.total_watt_hours ?? 0) / 1000).toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}{' '}
          kWh
        </TooltipContent>
      </Tooltip>
      <DialogContent
        className={cn({
          'w-full border-none bg-transparent p-0': onChain,
          'max-w-md': onChain,
          'max-w-2xl': onChain,
        })}
        aria-describedby={undefined}
      >
        <DialogHeader className="sr-only">
          <DialogTitle>Upvote</DialogTitle>
          <DialogDescription>
            Drag the point to adjust the upvote distribution
          </DialogDescription>
        </DialogHeader>
        {!onChain ? (
          <p className="mt-4">
            This archive isn't on chain yet. Archives must be saved on chain
            before they can be upvoted.
          </p>
        ) : (
          <UpvoteSelector archive={archive} onPayment={onPayment} />
        )}
      </DialogContent>
    </Dialog>
  )
}
