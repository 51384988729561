import { Button } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useAudioPlayer } from '@/hooks/useAudioPlayer'
import { cn } from '@/lib/utils'
import { AppRoutes } from '@/routes'
import { IArchive } from '@/types'
import {
  PauseIcon,
  PlayIcon,
  RotateCcwIcon,
  RotateCwIcon,
  XIcon,
} from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { SmallSlider } from '../ui/small-slider'

const SKIP_AMOUNT_SECONDS = 15

interface Props {
  archive?: IArchive
}

export function AudioPlayer(props: Props) {
  const { archive } = props

  const audioState = useAudioPlayer()

  const { archiveUrl, playbackRate, handleSeek, updateState, clearState } =
    audioState

  const showPreviewInformation = archive && archiveUrl !== archive?.url

  const isPlaying = showPreviewInformation ? false : audioState.isPlaying
  const title = showPreviewInformation ? archive?.title : audioState.title
  const titleLink = showPreviewInformation
    ? AppRoutes.buildArchiveRoute(archive?.url ?? '')
    : audioState.titleLink
  const artist = showPreviewInformation ? archive?.url : audioState.artist
  const duration = showPreviewInformation ? 0 : audioState.duration
  const progress = showPreviewInformation ? 0 : audioState.progress

  const handlePlayPause = () => {
    if (archive?.tts_url) {
      updateState({
        audioUrl: archive.tts_url,
        title: archive.title ?? new URL(archive.url).host,
        archiveUrl: archive.url,
        isPlaying:
          archive.tts_url === audioState.audioUrl && isPlaying ? false : true,
        artist: archive.url,
        titleLink: AppRoutes.buildArchiveRoute(archive.url),
      })
    } else {
      updateState({ isPlaying: !isPlaying })
    }
  }

  const handleSpeedChange = (value: string) => {
    updateState({ playbackRate: parseFloat(value) })
  }

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.floor(seconds % 60)
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
  }

  return (
    <div className="flex w-full flex-col gap-2">
      <div className={`flex flex-col items-center gap-2 sm:flex-row sm:gap-0`}>
        {title && (
          <div className="flex-1 sm:max-w-72">
            <ScrollingTitle
              title={title ?? ''}
              link={titleLink}
              className="truncate text-sm font-semibold underline-offset-4 hover:underline"
            />
            <div className="hidden truncate text-xs sm:block">{artist}</div>
          </div>
        )}
        {/* <div className='flex-1' /> */}
        <div className="flex flex-1 items-center justify-end gap-2">
          <div>
            <Select
              value={playbackRate.toString()}
              onValueChange={handleSpeedChange}
            >
              <SelectTrigger className="border-none bg-transparent p-0 hover:bg-transparent focus:ring-transparent">
                <SelectValue placeholder="Speed" />
              </SelectTrigger>
              <SelectContent align="end">
                <SelectItem value="0.5">0.5x</SelectItem>
                <SelectItem value="0.75">0.75x</SelectItem>
                <SelectItem value="1">1x</SelectItem>
                <SelectItem value="1.5">1.5x</SelectItem>
                <SelectItem value="1.75">1.75x</SelectItem>
                <SelectItem value="2">2x</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <Button
            size="icon"
            variant="ghost"
            onClick={() => {
              const seconds = progress * duration - SKIP_AMOUNT_SECONDS
              const newProgress = seconds / duration
              handleSeek(Math.max(0, newProgress))
            }}
          >
            <RotateCcwIcon className="size-4" />
          </Button>

          <Button
            variant="outline"
            size="icon"
            onClick={handlePlayPause}
            className="h-9 w-9 flex-shrink-0 rounded-full sm:justify-self-center"
          >
            {isPlaying ? (
              <PauseIcon className="h-4 w-4 fill-current" />
            ) : (
              <PlayIcon className="h-4 w-4 fill-current" />
            )}
          </Button>
          <Button
            size="icon"
            variant="ghost"
            onClick={() => {
              const seconds = progress * duration + SKIP_AMOUNT_SECONDS
              const newProgress = seconds / duration

              // For some reason setting to 1 resets the progress back to zero
              handleSeek(Math.min(0.999999, newProgress))
            }}
          >
            <RotateCwIcon className="size-4" />
          </Button>
          <Button variant="ghost" size="icon" onClick={clearState}>
            <XIcon className="size-4" />
          </Button>
        </div>
      </div>
      <div className="text-muted-foreground flex w-full justify-between gap-2 text-xs">
        <span>{formatTime(progress * duration)}</span>
        <SmallSlider
          variant="theme"
          value={[progress]}
          onValueChange={([v]) => handleSeek(v)}
          max={1}
          step={0.001}
          className="w-full"
        />
        <span>{formatTime(duration)}</span>
      </div>
    </div>
  )
}

interface ScrollingTitleProps {
  title: string
  link?: string
  className?: string
}

const INITIAL_SCROLL_DELAY = 3000
const SCROLL_DURATION = 9000
const SCROLL_INTERVAL = 5000

let scrollInterval: NodeJS.Timeout

export function ScrollingTitle({
  title,
  link,
  className = '',
}: ScrollingTitleProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLDivElement>(null)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current && textRef.current) {
        setIsOverflowing(
          textRef.current.scrollWidth > containerRef.current.clientWidth,
        )
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
  }, [title])

  const scrollText = () => {
    setScrollPosition((textRef.current?.scrollWidth ?? 0) / 2 + 20)

    setTimeout(() => {
      setScrollPosition(0)
    }, SCROLL_DURATION)
  }

  useEffect(() => {
    if (isOverflowing) {
      setTimeout(() => {
        scrollText()

        scrollInterval = setInterval(() => {
          scrollText()
        }, SCROLL_INTERVAL + SCROLL_DURATION)
      }, INITIAL_SCROLL_DELAY)

      return () => clearInterval(scrollInterval)
    }
  }, [isOverflowing])

  const content = (
    <div
      ref={containerRef}
      className={`relative overflow-hidden ${className}`}
      style={
        isOverflowing
          ? {
              boxShadow: 'inset -10px 0px 2px -2px rgb(165, 180, 252)',
            }
          : undefined
      }
    >
      <div
        ref={textRef}
        style={{
          transition: scrollPosition > 0 ? `linear ${SCROLL_DURATION}ms` : '',
          transform: `translateX(-${scrollPosition}px)`,
        }}
        className={`whitespace-nowrap`}
      >
        <span className={cn({ 'pr-10': isOverflowing })}>{title}</span>
        {isOverflowing && title}
      </div>
      {isOverflowing && (
        <div className="from-background via-background absolute inset-y-0 right-0 w-8 bg-gradient-to-l to-transparent" />
      )}
    </div>
  )

  return link ? (
    <Link to={link} className="underline-offset-4 hover:underline">
      {content}
    </Link>
  ) : (
    content
  )
}
