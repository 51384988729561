import { Routes as ReactRouterRoutes, Route } from 'react-router-dom'
import { Layout } from './Layout'
import { HomePage } from './pages/Home/HomePage'
import { ArchivePage } from './pages/Archive/ArchivePage'
import { FEED_SEARCH_PARAMS, FEED_VIEWS, FeedPage } from './pages/Feed/FeedPage'
import { AdminPage } from './pages/Archive/Admin/AdminPage'
import { AboutPage } from './pages/About/AboutPage'
import { StatsPage } from './pages/StatsPage'
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage'
import { DownloadsPage } from './pages/DownloadsPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { LoginPage } from './pages/LoginPage'
import { BookmarksPage } from './pages/BookmarksPage'
import { SignupPage } from './pages/SignupPage'
import { ProtectedLayout } from './ProtectedLayout'
import {
  WALLET_SEARCH_PARAMS,
  WALLET_TABS,
  WalletPage,
} from './pages/WalletPage'
import { DigDeeperPage } from './pages/About/DiggingDeeperPage'
import { ROUTES } from 'backend/src/lib/constants'
import { NewArchivePage } from './pages/NewArchivePage'

type WalletTab = (typeof WALLET_TABS)[keyof typeof WALLET_TABS]
type FeedView = (typeof FEED_VIEWS)[keyof typeof FEED_VIEWS]

// eslint-disable-next-line react-refresh/only-export-components
export const AppRoutes = {
  ...ROUTES,
  buildArchiveRoute: (url: string) =>
    `${ROUTES.ARCHIVE}?url=${encodeURIComponent(url)}`,
  buildWalletRoute: (tab?: WalletTab) =>
    `${ROUTES.WALLET}?${WALLET_SEARCH_PARAMS.TAB}=${tab}`,
  buildFeedRoute: (view?: FeedView) =>
    `${ROUTES.FEED}?${FEED_SEARCH_PARAMS.VIEW}=${view}`,
}

export function Routes() {
  return (
    <ReactRouterRoutes>
      <Route element={<Layout />}>
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.NEW_ARCHIVE} element={<NewArchivePage />} />
        <Route path={ROUTES.ARCHIVE} element={<ArchivePage />} />
        <Route path={ROUTES.BROWSE} element={<FeedPage />} />
        <Route path={ROUTES.FEED} element={<FeedPage />} />
        <Route path={ROUTES.ADMIN} element={<AdminPage />} />
        <Route path={ROUTES.ABOUT} element={<AboutPage />} />
        <Route path={ROUTES.DIGGING_DEEPER} element={<DigDeeperPage />} />
        <Route path={ROUTES.STATS} element={<StatsPage />} />
        <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
        <Route path={ROUTES.DOWNLOADS} element={<DownloadsPage />} />
        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={ROUTES.SIGNUP} element={<SignupPage />} />

        <Route element={<ProtectedLayout />}>
          <Route path={ROUTES.SAVED} element={<BookmarksPage />} />
          <Route path={ROUTES.WALLET} element={<WalletPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </ReactRouterRoutes>
  )
}
