import { toast } from '@/components/ui/use-toast'
import { api } from '@/lib/api'
import { compactNumber, mhtmlToHtml } from '@/lib/formatting'

import { Authentication } from '@/components/Authentication'
import { useTheme } from '@/components/ThemeProvider'
import { Button } from '@/components/ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { UpvoteButton } from '@/components/upvoting/UpvoteButton'
import { useAnalytics } from '@/hooks/useAnalytics'
import { usePrevious } from '@/hooks/usePrevious'
import useUser from '@/hooks/useUser'
import { cn } from '@/lib/utils'
import { Wallet } from '@canonicxyz/wallet-sdk'
import { EventChannels } from 'backend/src/lib/event-channels'
import {
  ChevronDownIcon,
  CircleXIcon,
  LoaderCircleIcon,
  MessageSquareIcon,
} from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSearchParams } from 'react-router-dom'
import { ActionButtonGroup } from './components/ActionButtonGroup'
import { ArchiveContent } from './components/ArchiveContent'
import { AudioButton } from './components/AudioButton'
import { Comments } from './components/Comments'
import { OnChainStatus } from './components/OnChainStatus'
import { Separator } from '@/components/ui/separator'

export function ArchivePage() {
  const [params] = useSearchParams()

  // URL is encoded
  const url = params.get('url') || ''

  const [showPayModal, setPayModal] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const [showComments, setShowComments] = useState(false)
  const analytics = useAnalytics()

  const { theme } = useTheme()

  const {
    data: archive,
    isLoading: loadingArchive,
    refetch: refetchArchive,
  } = api.archives.view.useQuery(
    { url },
    {
      refetchOnWindowFocus: !showPayModal,
      refetchOnReconnect: !showPayModal,
      refetchOnMount: !showPayModal,
    },
  )

  const { data: totalComments } = api.comments.count.useQuery({ url })

  const scrapeMutation = api.archives.scrape.useMutation({
    onSettled: () => {
      refetchArchive()
    },
  })

  const { user } = useUser()

  const { addEventListener, clearEventListeners } = useSse(url)

  const onPayment = async () => {
    toast({ title: 'Payment Received' })

    analytics.sendEvent('payment_made', { url })

    if (!showPayModal) {
      await refetchArchive()
    }

    if (Wallet.isLoggedIn()) {
      await refetchArchive()
      setPayModal(false)
    }
  }

  const onMinted = async () => {
    toast({ title: 'Minted' })

    if (!showPayModal) {
      await refetchArchive()
    }
  }

  const previousShowPayModal = usePrevious(showPayModal)

  const utils = api.useUtils()

  useEffect(() => {
    // Need to clear event listeners when the pay modal is opened/closed
    // so we have the latest value of showPayModal
    if (
      typeof previousShowPayModal !== 'undefined' &&
      showPayModal !== previousShowPayModal
    ) {
      clearEventListeners()
    }

    addEventListener('paymentReceived', onPayment)
    addEventListener('minted', onMinted)
    addEventListener('archiveCompleted', async () => {
      await refetchArchive()

      utils.bitcoin.invalidate()
    })
    addEventListener('archiveFailed', async () => await refetchArchive())
  }, [addEventListener])

  useEffect(() => {
    if (previousShowPayModal && !showPayModal) {
      refetchArchive()
    }
  }, [showPayModal])

  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'resize' && archive) {
        iframeRef.current?.style.setProperty(
          'height',
          `${event.data.height + 20}px`,
        )
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [archive])

  if (loadingArchive) {
    return (
      <div className="flex justify-center pt-8 lg:pt-14">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    )
  }

  if (archive?.status === 'scraping') {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="flex justify-center">
          <LoaderCircleIcon className="h-8 w-8 animate-spin antialiased" />
        </div>
        <h1 className="text-2xl font-bold">Archiving in Progress</h1>
        <p className="text-muted-foreground mt-2 text-center">
          This page will automatically refresh when the archive is ready.
        </p>
      </div>
    )
  }

  if (archive?.status === 'failed') {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="flex justify-center">
          <CircleXIcon className="h-8 w-8 antialiased" />
        </div>
        <h1 className="text-2xl font-bold">Archiving Failed</h1>
        <p className="text-muted-foreground mt-2 text-center">
          This page failed to archive. Please use the button below to try again.
          If the problem persists, please contact support.
        </p>
        <div className="mt-6">
          <Button onClick={() => scrapeMutation.mutate({ url })}>Retry</Button>
        </div>
      </div>
    )
  }

  if (!archive || archive.status !== 'completed') {
    return <div className="flex justify-center pt-8 lg:pt-14">Not found :(</div>
  }

  let iframeSrc =
    archive.format === 'mhtml'
      ? mhtmlToHtml(archive.text_content || '')
      : undefined

  if (iframeSrc) {
    iframeSrc = iframeSrc.replace(
      '</head>',
      `
      <style>
        body {
          color: ${theme === 'dark' ? 'white' : 'black'};
        }

        ${
          theme === 'system' &&
          `
          @media (prefers-color-scheme: dark) {
            body {
              color: white;
            }
          }
        `
        }

      </style>
      <script>
        function sendHeight() {
          const height = document.body.scrollHeight;
          window.parent.postMessage({ type: 'resize', height }, '*');
        }

        // Send height when the page loads
        window.addEventListener('load', sendHeight);

        // Optionally, send height when the window is resized
        window.addEventListener('resize', sendHeight);
      </script>
    </head>`,
    )
  }

  const sortedMedia = archive.media ?? []
  sortedMedia.sort((a, b) => {
    if (a.mime_type.startsWith('image/') && !b.mime_type.startsWith('image/')) {
      return -1
    }
    return 1
  })

  return (
    <div className="mx-auto w-full max-w-screen-lg pb-8 pt-8 lg:pb-14">
      <Helmet title={`${archive.title ?? archive.url}`} />
      <div className="mx-auto mb-8 max-w-prose">
        <OnChainStatus
          archive={archive}
          payModal={showPayModal}
          setPayModal={setPayModal}
        />
      </div>

      <div className="mx-auto w-full max-w-prose">
        <div className="flex items-center gap-1 md:gap-0">
          <div className="relative w-full">
            <div className="absolute inset-y-0 -left-2 hidden -translate-x-full items-start font-mono font-normal md:flex">
              <UpvoteButton archive={archive} />
            </div>
            <a
              href={archive.url}
              target="_blank"
              rel="noreferrer noopener"
              className="mb-1 break-words font-sans text-2xl font-black md:text-3xl"
            >
              {archive.title ?? archive?.url}
            </a>

            {archive.author && (
              <div className="text-muted-foreground mt-2 flex flex-1 text-sm">
                <div className="truncate">{archive.author}</div>
              </div>
            )}
          </div>
        </div>

        <Separator className="my-4" />
        <div className="mt-4 flex items-center gap-2">
          <div className="flex-1 sm:hidden">
            <UpvoteButton archive={archive} />
          </div>

          <ActionButtonGroup archive={archive} totalComments={totalComments} />
        </div>

        <AudioButton archive={archive} />

        <ArchiveContent
          archive={archive}
          iframeRef={iframeRef}
          iframeSrc={iframeSrc}
          className="mt-12"
        />

        {showLogin && !user ? (
          <div className="mt-10">
            <Authentication view="login" inline />
          </div>
        ) : (
          !user && (
            <div className="bg-muted/50 mt-10 flex items-center gap-4 rounded-lg p-4">
              <p className="text-muted-foreground text-sm">
                You must be logged in to vote and comment.
              </p>
              <Button onClick={() => setShowLogin(true)}>Log In</Button>
            </div>
          )
        )}

        <Collapsible>
          <CollapsibleTrigger asChild>
            <Button
              id="comments"
              onClick={() => setShowComments(!showComments)}
              variant="secondary"
              className="text-muted-foreground mt-10 w-full"
            >
              <MessageSquareIcon className="size-4" />
              {showComments ? 'Hide' : 'Show'} comments
              {totalComments ? ` (${compactNumber(totalComments ?? 0)})` : ''}
              <ChevronDownIcon
                className={cn(
                  'size-5 transition-transform',
                  showComments && 'rotate-180',
                )}
              />
            </Button>
          </CollapsibleTrigger>
          <CollapsibleContent className="mt-10">
            <Comments archive={archive} />
          </CollapsibleContent>
        </Collapsible>
      </div>
    </div>
  )
}

const getSseUrl = (url: string) => `/api/sse?url=${url}`

function useSse(url: string) {
  const [sse, setSse] = useState<EventSource | null>(null)

  useEffect(() => {
    const sseUrl = getSseUrl(url)
    const sse = new EventSource(sseUrl)

    setSse(sse)

    return () => {
      sse?.close()
    }
  }, [url])

  const addEventListener = <Event extends keyof EventChannels>(
    event: Event,
    cb: (data: EventChannels[Event]) => void,
  ) => {
    sse?.addEventListener(event, ({ data }) => {
      cb(JSON.parse(data))
    })
  }

  const clearEventListeners = () => {
    sse?.close()

    const newSse = new EventSource(getSseUrl(url))
    setSse(newSse)
  }

  return { addEventListener, clearEventListeners }
}
