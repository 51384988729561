import { useMemo, useState } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Checkbox } from '@/components/ui/checkbox'
import { Button } from '@/components/ui/button'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Badge } from '@/components/ui/badge'
import {
  ArrowUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  LoaderCircleIcon,
  SearchIcon,
  TrashIcon,
  XCircleIcon,
} from 'lucide-react'

import { IArchive } from '@/types'
import {
  formatCurrency,
  formatDate,
  formatDateWithTime,
} from '@/lib/formatting'
import { AppRoutes } from '@/routes'
import { Link } from 'react-router-dom'
import { api } from '@/lib/api'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/use-toast'
import { cn } from '@/lib/utils'
import { Input } from '@/components/ui/input'
import { ArchiveActionMenu } from './ArchiveActionMenu'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { FeeSelection } from '@/components/FeeSelection'

const colHelper = createColumnHelper<IArchive>()

interface Props {
  archives: IArchive[]
  hasNextPage: boolean
  hasPreviousPage: boolean
  pageSize: number
  currentPage: number
  pageCount: number
  adminSecret: string
  walletBalance: number | undefined
  search: string
  isLoading: boolean
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  refetchData: () => void
  setPageSize: (size: number) => void
  nextPage: () => void
  previousPage: () => void
}

export function ArchiveTable(props: Props) {
  const {
    archives,
    hasNextPage,
    hasPreviousPage,
    pageSize,
    currentPage,
    pageCount,
    refetchData,
    adminSecret,
    walletBalance,
    setPageSize,
    handleSearchChange,
    search,
    isLoading,
    nextPage,
    previousPage,
  } = props

  const { data: btcInfo } = api.bitcoin.priceInfo.useQuery()

  const toast = useToast()

  const [confirmApprovalModal, setConfirmApprovalModal] = useState<
    'standard' | 'premium' | null
  >(null)
  const [selectedUrls, setSelectedUrls] = useState<Set<string>>(new Set())
  const [updateTitleArchive, setUpdateTitleArchive] = useState<IArchive | null>(
    null,
  )
  const [showDelete, setShowDelete] = useState(false)

  const [customSatsPerByte, setCustomSatsPerByte] = useState<number | null>(
    null,
  )
  const [customSatsPerByteValid, setCustomSatsPerByteValid] = useState(true)

  const { data: fees, isLoading: loadingFees } =
    api.bitcoin.estimateFees.useQuery(
      {
        urls: Array.from(selectedUrls),
        satsPerByte: customSatsPerByte ? customSatsPerByte : undefined,
      },
      { enabled: selectedUrls.size > 0 },
    )

  const standardFees = fees?.standardFeesUsd
  const premiumFees = fees?.premiumFeesUsd

  const approveMutation = api.admin.approve.useMutation({
    onSuccess: async ({ skippedUrls }) => {
      const description = skippedUrls.length
        ? `${skippedUrls.length} archive(s) were skipped because they have already been minted`
        : undefined

      toast.toast({ title: 'Archive(s) Approved', description })

      refetchData()

      setConfirmApprovalModal(null)

      setSelectedUrls(new Set())
    },
    onError: (error) => {
      toast.toast({
        title: 'Error Approving Archive(s)',
        description: error.message,
        variant: 'destructive',
        duration: Infinity,
      })
    },
  })

  const deleteMutation = api.admin.delete.useMutation({
    onSuccess: async () => {
      toast.toast({ description: 'Archive(s) Deleted' })

      refetchData()

      setShowDelete(false)

      setSelectedUrls(new Set())
    },
    onError: (error) => {
      toast.toast({
        title: 'Error Deleting Archive(s)',
        description: error.message,
        variant: 'destructive',
        duration: Infinity,
      })
    },
  })

  const scrapeMutation = api.admin.rescrape.useMutation({
    onMutate: () => {
      toast.toast({
        description: (
          <div className="flex items-center gap-1.5">
            Rescraping... <LoaderCircleIcon className="h-5 w-5 animate-spin" />
          </div>
        ),
      })
    },
    onSuccess: async () => {
      toast.toast({ description: 'Rescraped!' })

      refetchData()

      setSelectedUrls(new Set())
    },
    onError: (error) => {
      toast.toast({
        title: 'Error Rescraping',
        description: error.message,
        variant: 'destructive',
        duration: Infinity,
      })
    },
  })

  const setTitleMutation = api.admin.updateTitle.useMutation({
    onSuccess: async () => {
      toast.toast({ description: 'Title Updated' })

      setUpdateTitleArchive(null)

      refetchData()

      setSelectedUrls(new Set())
    },
    onError: (error) => {
      toast.toast({
        title: 'Error Updating Title',
        description: error.message,
        variant: 'destructive',
        duration: Infinity,
      })
    },
  })

  const toggleFeaturedMutation = api.admin.toggleFeatured.useMutation({
    onSuccess: async () => {
      toast.toast({ description: 'Saved' })

      refetchData()
    },
    onError: (error) => {
      toast.toast({
        title: 'Error Updating Featured Status',
        description: error.message,
        variant: 'destructive',
        duration: Infinity,
      })
    },
  })

  const columns = useMemo(
    () => [
      colHelper.display({
        id: 'select',
        header: () => {
          const selectableArchives = archives.filter(
            (a) => !a.minting_in_progress,
          )

          return (
            <Checkbox
              checked={
                selectedUrls.size > 0
                  ? selectedUrls.size === selectableArchives.length
                  : false
              }
              onCheckedChange={(checked) => {
                if (checked) {
                  setSelectedUrls(new Set(selectableArchives.map((a) => a.url)))
                } else {
                  setSelectedUrls(new Set())
                }
              }}
              aria-label="Select all"
            />
          )
        },
        cell: ({ row }) => (
          // The entire row is clickable, so we don't need to add an onChange handler
          <Checkbox
            checked={selectedUrls.has(row.original.url)}
            aria-label="Select row"
          />
        ),
      }),
      colHelper.accessor('url', {
        header: 'URL',
        cell: ({ getValue }) => {
          const url = getValue()
          return (
            <div className="flex w-full max-w-lg">
              <Link
                to={AppRoutes.buildArchiveRoute(url)}
                className="block truncate underline-offset-4 hover:underline"
                target="_blank"
                title={url}
              >
                {url}
              </Link>
            </div>
          )
        },
      }),
      colHelper.accessor('title', {
        header: 'Title',
        enableSorting: true,
        cell: ({ getValue }) => {
          return (
            <div title={getValue()} className="max-w-[15rem] truncate">
              {getValue()}
            </div>
          )
        },
      }),
      colHelper.display({
        id: 'status',
        cell: ({ row }) => {
          const hashSaved = !!row.original.hash_tx
          const fullTextSaved = !!row.original.full_text_tx

          return (
            <div className="flex items-center gap-2">
              {hashSaved && <Badge>Hash</Badge>}
              {fullTextSaved && <Badge>Full Text</Badge>}
            </div>
          )
        },
        header: 'On-Chain',
      }),
      colHelper.accessor('date', {
        header: 'Date',
        cell: ({ getValue }) => {
          return (
            <div
              title={formatDateWithTime(getValue())}
              className="whitespace-nowrap"
            >
              {formatDate(getValue())}
            </div>
          )
        },
      }),
      colHelper.display({
        id: 'actions',
        cell: ({ row }) => {
          const disableButtons =
            approveMutation.isPending ||
            scrapeMutation.isPending ||
            deleteMutation.isPending ||
            !walletBalance

          return row.original.minting_in_progress ? (
            <div className="flex justify-center">
              <LoaderCircleIcon className="h-4 w-4 animate-spin" />
            </div>
          ) : (
            <ArchiveActionMenu
              archive={row.original}
              disableButtons={disableButtons}
              setShowDelete={setShowDelete}
              setUpdateTitleArchive={setUpdateTitleArchive}
              scrapeFn={(url) =>
                scrapeMutation.mutate({ url, secret: adminSecret })
              }
              deleteFn={(url) =>
                deleteMutation.mutate({ urls: [url], secret: adminSecret })
              }
              toggleFeaturedFn={(url) =>
                toggleFeaturedMutation.mutate({ url, secret: adminSecret })
              }
            />
          )
        },
      }),
    ],
    [archives, walletBalance, selectedUrls],
  )

  const table = useReactTable({
    data: archives,
    columns,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    pageCount,
    state: { pagination: { pageIndex: currentPage - 1, pageSize } },
    initialState: {
      sorting: [{ id: 'date', desc: true }],
    },
  })

  const premiumDisabled =
    selectedUrls.size !== 1 ||
    (selectedUrls.size === 1 &&
      typeof premiumFees !== 'undefined' &&
      premiumFees === null)

  const toggleSelected = (url: string) => {
    const newSelectedUrls = new Set(selectedUrls)
    if (selectedUrls.has(url)) {
      newSelectedUrls.delete(url)
    } else {
      newSelectedUrls.add(url)
    }

    setSelectedUrls(newSelectedUrls)
  }
  return (
    <div>
      <div className="mb-4 flex flex-col items-center justify-between gap-2 sm:flex-row sm:gap-8">
        <div className="relative w-full flex-1 sm:w-auto">
          <div className="pointer-events-none absolute left-2 flex h-full items-center">
            <SearchIcon className="stroke-muted-foreground h-4 w-4" />
          </div>
          <Input
            onChange={handleSearchChange}
            value={search}
            placeholder="Search by URL or title"
            className="px-8"
          />
          <div className="absolute inset-y-0 right-2 flex h-full items-center">
            <button
              className={cn({
                'opacity-100': search,
                'pointer-events-none opacity-0': !search,
              })}
              onClick={() =>
                handleSearchChange({
                  target: { value: '' },
                } as React.ChangeEvent<HTMLInputElement>)
              }
            >
              <XCircleIcon className={`h-4 w-4`} />
            </button>
          </div>
        </div>
        <div className="flex items-center justify-end gap-2">
          <span className="text-sm font-medium">
            {selectedUrls.size} selected
          </span>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                className="gap-1.5"
                disabled={selectedUrls.size === 0}
              >
                Manage <ChevronDownIcon className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="mr-6">
              <DropdownMenuItem
                onClick={() => setConfirmApprovalModal('standard')}
              >
                Approve hash -{' '}
                {loadingFees ? (
                  <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                ) : (
                  formatCurrency(standardFees ?? 0)
                )}
              </DropdownMenuItem>

              <DropdownMenuItem
                disabled={premiumDisabled}
                onClick={() => setConfirmApprovalModal('premium')}
              >
                Approve full text -{' '}
                {premiumDisabled ? (
                  'unavailable'
                ) : loadingFees ? (
                  <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                ) : (
                  formatCurrency(premiumFees ?? 0)
                )}
              </DropdownMenuItem>
              <DropdownMenuSeparator />

              <DropdownMenuItem
                onClick={() => setShowDelete(true)}
                className="gap-1.5"
              >
                <TrashIcon className="h-4 w-4" />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      {isLoading && (
        <div className="flex justify-center pt-8 lg:pt-14">
          <LoaderCircleIcon className="animate-spin" />
        </div>
      )}
      {!isLoading && (
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex items-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                      {header.column.getCanSort() &&
                        header.column.getIsSorted() && (
                          <ArrowUpIcon
                            className={`ml-2 h-4 w-4 ${cn(
                              header.column.getIsSorted() === 'asc',
                              'rotate-180',
                            )}`}
                          />
                        )}
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                className={cn({
                  'opacity-50': row.original.minting_in_progress,
                })}
                onClick={(e) => {
                  if (row.original.minting_in_progress) return

                  const target = e.target as HTMLElement
                  const role = target.getAttribute('role')

                  // Make sure it's not the dropdown menu or link
                  const disableSelection =
                    !row.getCanSelect() ||
                    role === 'menuitem' ||
                    target.nodeName === 'A'

                  if (!disableSelection) {
                    toggleSelected(row.original.url)
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex items-center gap-2">
          <Label className="whitespace-nowrap">Page size:</Label>
          <Select
            value={pageSize.toString()}
            onValueChange={(value) => setPageSize(Number(value))}
          >
            <SelectTrigger className="w-[4.5rem]">
              <SelectValue placeholder="Default" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="10">10</SelectItem>
              <SelectItem value="25">25</SelectItem>
              <SelectItem value="50">50</SelectItem>
              <SelectItem value="100">100</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <Button
          variant="outline"
          size="sm"
          onClick={previousPage}
          disabled={!hasPreviousPage}
        >
          <ChevronLeftIcon className="h-4 w-4" /> Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={nextPage}
          disabled={!hasNextPage}
        >
          Next <ChevronRightIcon className="h-4 w-4" />
        </Button>
      </div>

      <Dialog
        open={!!confirmApprovalModal}
        onOpenChange={(v) =>
          setConfirmApprovalModal(v ? confirmApprovalModal : null)
        }
      >
        {confirmApprovalModal && (
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                Approve {selectedUrls.size} Archive
                {selectedUrls.size === 1 ? '' : 's'}
              </DialogTitle>
              <DialogDescription className="mt-2">
                Are you sure you want to approve the{' '}
                <b className="text-primary">
                  {confirmApprovalModal === 'standard' ? 'hash' : 'full text'}
                </b>{' '}
                archival for the selected archive
                {selectedUrls.size === 1 ? '' : 's'}?
              </DialogDescription>
            </DialogHeader>
            {typeof btcInfo !== 'undefined' &&
              btcInfo.sats_per_kb_btc_mempool && (
                <div className="mt-6">
                  <FeeSelection
                    onChange={(fee) => {
                      setCustomSatsPerByte(fee.satsPerByte)
                      setCustomSatsPerByteValid(fee.valid)
                    }}
                    minimumFee={1}
                  />
                </div>
              )}
            <DialogFooter className="mt-4 flex w-full items-center gap-3 sm:justify-between">
              <DialogClose asChild>
                <Button variant="outline">Cancel</Button>
              </DialogClose>
              <Button
                disabled={
                  approveMutation.isPending ||
                  loadingFees ||
                  (confirmApprovalModal === 'premium' && premiumDisabled) ||
                  !customSatsPerByteValid
                }
                onClick={() => {
                  approveMutation.mutate({
                    type: confirmApprovalModal,
                    urls: Array.from(selectedUrls),
                    secret: adminSecret,
                    satsPerByte:
                      customSatsPerByteValid && customSatsPerByte
                        ? customSatsPerByte
                        : null,
                  })
                }}
              >
                Approve for{' '}
                {confirmApprovalModal === 'standard' ? (
                  loadingFees ? (
                    <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                  ) : standardFees === null ? (
                    'unavailable'
                  ) : (
                    formatCurrency(standardFees ?? 0)
                  )
                ) : null}
                {confirmApprovalModal === 'premium' ? (
                  loadingFees ? (
                    <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                  ) : premiumDisabled ? (
                    'unavailable'
                  ) : (
                    formatCurrency(premiumFees ?? 0)
                  )
                ) : null}
              </Button>
            </DialogFooter>
          </DialogContent>
        )}
      </Dialog>
      <AlertDialog open={showDelete} onOpenChange={setShowDelete}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Delete Archive{selectedUrls.size === 1 ? '' : 's'}
            </AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                deleteMutation.mutate({
                  urls: Array.from(selectedUrls),
                  secret: adminSecret,
                })
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Dialog
        open={!!updateTitleArchive}
        onOpenChange={(open) =>
          setUpdateTitleArchive(open ? updateTitleArchive : null)
        }
      >
        <DialogContent aria-describedby={undefined}>
          <DialogHeader>
            <DialogTitle>Update Title</DialogTitle>
          </DialogHeader>
          <form
            className="flex flex-col gap-4"
            onSubmit={(e) => {
              e.preventDefault()

              const formData = new FormData(e.target as HTMLFormElement)
              const title = formData.get('title')

              if (!title || typeof title !== 'string') {
                return toast.toast({
                  description: 'Title is required',
                  variant: 'destructive',
                  duration: Infinity,
                })
              }
              const url = updateTitleArchive!.url

              setTitleMutation.mutate({
                url,
                title,
                secret: adminSecret,
              })
            }}
          >
            <Input
              name="title"
              defaultValue={updateTitleArchive?.title ?? ''}
            />
            <Button type="submit" loading={setTitleMutation.isPending}>
              Update
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
