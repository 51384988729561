import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { IArchive, IMedia } from '@/types'
import { FileIcon } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  media: IMedia
}

export function MediaPreview(props: Props) {
  const { media } = props

  return media.mime_type.startsWith('image/') ? (
    <Dialog key={media.url}>
      <DialogTrigger asChild>
        <img
          key={media.url}
          src={media.url}
          alt={media.url}
          className="size-20 cursor-pointer object-cover"
        />
      </DialogTrigger>
      <DialogContent
        aria-describedby={undefined}
        className="w-auto border-none p-0"
        hideCloseButton
      >
        <DialogHeader className="sr-only">
          <DialogTitle>{media.url}</DialogTitle>
        </DialogHeader>
        <div className="overlfow-hidden">
          <img
            src={media.url}
            alt={media.url}
            width={media.width}
            height={media.height}
          />
        </div>
      </DialogContent>
    </Dialog>
  ) : (
    <Link
      to={media.url}
      target="_blank"
      className="bg-muted text-muted-foreground flex size-20 flex-col items-center justify-center gap-1 rounded-md"
    >
      <FileIcon className="h-4 w-4" />
      <p className="text-xs uppercase">
        {media.mime_type.split('/')[1].split('+')[0]}
      </p>
    </Link>
  )
}
