import { useAnalytics } from '@/hooks/useAnalytics'
import { api } from '@/lib/api'
import { cn } from '@/lib/utils'
import { AppRoutes } from '@/routes'
import { LoaderCircleIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { Button } from './ui/button'
import { Input } from './ui/input'
import { toast } from './ui/use-toast'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Label } from './ui/label'

const FormFieldsSchema = z.object({
  url: z.string().url(),
  title: z.string().optional(),
})

type FormFields = z.infer<typeof FormFieldsSchema>

interface Props {
  defaultUrl?: string
  onSuccess?: (url: string) => void
  interrupt?: () => void
  setInterruptedAction?: (action: () => () => void) => void
}

export function CreateArchiveForm(props: Props) {
  const { defaultUrl, onSuccess, interrupt, setInterruptedAction } = props

  const { register, handleSubmit, reset } = useForm<FormFields>({
    resolver: zodResolver(FormFieldsSchema),
    defaultValues: {
      url: defaultUrl ?? '',
      title: '',
    },
  })

  const analytics = useAnalytics()
  const navigate = useNavigate()

  const mutation = api.archives.scrape.useMutation({
    onSuccess: async ({ url, alreadyExists }) => {
      if (alreadyExists) {
        toast({
          title: 'Archive Already Exists',
          description: 'The provided URL has already been archived',
        })
      } else {
        await new Promise((resolve) => setTimeout(resolve, 2000))
      }

      navigate(AppRoutes.buildArchiveRoute(url))

      reset()
      onSuccess?.(url)
    },
    onError: (error) => {
      toast({
        title: 'Error Archiving',
        description: error.message,
        variant: 'destructive',
      })
    },
  })

  const saveArchive = (data: FormFields) => {
    analytics.sendEvent('page_scraped', { sraped_url: data.url })

    mutation.mutate({ url: data.url, title: data.title })
  }

  const onSubmit = (data: FormFields) => {
    if (interrupt) {
      setInterruptedAction?.(() => () => saveArchive(data))
      interrupt()
    } else {
      saveArchive(data)
    }
  }

  return (
    <form
      className={`flex flex-1 flex-col items-center justify-between gap-2`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Label htmlFor="url" className="w-full text-left">
        URL
      </Label>
      <Input
        type="text"
        placeholder={'Enter a URL'}
        id="url"
        {...register('url')}
        autoFocus={!defaultUrl}
      />
      <Label htmlFor="title" className="mt-4 w-full text-left">
        Title (optional)
      </Label>
      <Input
        type="text"
        id="title"
        placeholder="Enter a title (optional)"
        {...register('title')}
        autoFocus={!!defaultUrl}
      />
      <Button
        type="submit"
        variant={'theme'}
        className={`w-full px-5`}
        disabled={mutation.isPending}
      >
        {mutation.isPending ? 'Archiving...' : 'Create'}
        {mutation.isPending && <LoaderCircleIcon className="animate-spin" />}
      </Button>
    </form>
  )
}
