import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { DialogTrigger } from '@/components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { IArchive } from '@/types'
import {
  EllipsisIcon,
  RefreshCcwIcon,
  LetterTextIcon,
  TrashIcon,
  StarIcon,
} from 'lucide-react'
import { useState } from 'react'

interface Props {
  archive: IArchive
  disableButtons: boolean
  setShowDelete: (showDelete: boolean) => void
  setUpdateTitleArchive: (archive: IArchive | null) => void
  scrapeFn: (url: string) => void
  toggleFeaturedFn: (url: string) => void
  deleteFn: (url: string) => void
}

export function ArchiveActionMenu(props: Props) {
  const {
    archive,
    disableButtons,
    deleteFn,
    setUpdateTitleArchive,
    scrapeFn,
    toggleFeaturedFn,
  } = props

  const fullTextSaved = !!archive.full_text_tx

  const [showDelete, setShowDelete] = useState(false)

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <EllipsisIcon className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mr-6">
          {!fullTextSaved && (
            <DropdownMenuItem
              className="w-full gap-1.5"
              disabled={disableButtons}
              onClick={() => scrapeFn(archive.url)}
            >
              <RefreshCcwIcon className="h-4 w-4" />
              Rescrape
            </DropdownMenuItem>
          )}

          <DropdownMenuSeparator />

          <DropdownMenuItem
            className="gap-1.5"
            onClick={() => setUpdateTitleArchive(archive)}
          >
            <LetterTextIcon className="h-4 w-4" />
            Update title
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="gap-1.5"
            onClick={() => toggleFeaturedFn(archive.url)}
          >
            <StarIcon className="h-4 w-4" />
            {archive.featured ? 'Remove as featured' : 'Make featured'}
          </DropdownMenuItem>
          <DropdownMenuSeparator />

          <DropdownMenuItem
            disabled={disableButtons}
            className="gap-1.5"
            onClick={() => setShowDelete(true)}
          >
            <TrashIcon className="h-4 w-4" />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <AlertDialog open={showDelete} onOpenChange={setShowDelete}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Archive</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                deleteFn(archive.url)
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
