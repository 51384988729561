import useUser from '@/hooks/useUser'
import { AppRoutes } from '@/routes'
import { LogOutIcon } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar'
import { buttonVariants } from './ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './ui/dropdown-menu'

export function ProfileLink() {
  const { user, loading, logout } = useUser()

  if (!user && !loading) {
    return (
      <Link
        to={AppRoutes.LOGIN}
        className={buttonVariants({ variant: 'outline' })}
      >
        Login
      </Link>
    )
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar className="h-10 w-10 cursor-pointer rounded-full">
          <AvatarImage src={user?.image} alt={user?.paymail} />
          <AvatarFallback className="animate-pulse"></AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem asChild>
          <Link to={AppRoutes.WALLET}>Wallet</Link>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <button
            onClick={logout}
            className="flex w-full items-center justify-between"
          >
            Logout <LogOutIcon className="size-4" />
          </button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
