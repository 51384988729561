import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '@/routes'
import useUser from '@/hooks/useUser'
import { useEffect } from 'react'
import { Authentication } from '@/components/Authentication'
import { LoginBenefits } from './LoginBenefits'

export function SignupPage() {
  const { user } = useUser()

  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate(AppRoutes.SAVED)
    }
  }, [user])

  return (
    <div className="mt-10 flex flex-1 flex-col items-center justify-center gap-6 sm:mt-0">
      <Authentication
        view="signup"
        onSuccess={() => navigate(AppRoutes.SAVED)}
        className="mx-auto w-full max-w-md"
      />
      <div className="relative my-6 w-full max-w-md">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background text-muted-foreground px-2 tracking-wider">
            benefits
          </span>
        </div>
      </div>
      <LoginBenefits />
    </div>
  )
}
