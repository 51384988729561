import { SATS_PER_BITCOIN } from '@/lib/constants'
import { formatCurrency } from '@/lib/formatting'
import { useEffect, useState } from 'react'
import { DataSwitcher } from './DataSwitcher'

interface Props {
  sats: number
  bitcoinPrice: number
  className?: string
}
/** On click, switch between sats, bitcoin and USD prices */
export function PriceSwitcher(props: Props) {
  const { bitcoinPrice, className } = props

  const sats = `${props.sats.toLocaleString()} sats`
  const bitcoin = `${Number((props.sats / SATS_PER_BITCOIN).toFixed(6))} BTC`
  const usd = formatCurrency(
    Number(((props.sats / SATS_PER_BITCOIN) * bitcoinPrice).toFixed(2)),
  )

  return (
    // <div onClick={toggleFeeUnit} className={className}>
    //   {feeUnit === 'usd' && formatCurrency(totalFee)}
    //   {feeUnit === 'sats' && new Intl.NumberFormat('en-us').format(totalFee)}
    //   {feeUnit === 'bitcoin' && totalFee} {feeUnit !== 'usd' ? feeUnit : null}
    // </div>
    <DataSwitcher values={[sats, bitcoin, usd]} className={className} />
  )
}
