// import Slider from 'rc-slider'
import { useDebouncedInput } from '@/hooks/useDebouncedInput'
import { api } from '@/lib/api'
import { useEffect, useState } from 'react'
import { Label } from './ui/label'
import { Input } from './ui/input'
import { inferRouterOutputs } from '@trpc/server'
import { AppRouter } from 'backend/src/routers/app.router'
import 'rc-slider/assets/index.css'
import { Slider } from './ui/slider'
import { cn } from '@/lib/utils'

type FeeRate = keyof NonNullable<
  inferRouterOutputs<AppRouter>['bitcoin']['priceInfo']['sats_per_kb_btc_mempool']
>

const feeLabels: Record<FeeRate, string> = {
  economyFee: 'Economy',
  minimumFee: 'Minimum',
  halfHourFee: 'Half Hour',
  hourFee: 'Hour',
  fastestFee: 'Fastest',
}

interface Props {
  onChange: (data: { satsPerByte: number; valid: boolean }) => void
  minimumFee?: number
}

export function FeeSelection(props: Props) {
  const { onChange } = props

  const { data: btcInfo } = api.bitcoin.priceInfo.useQuery()
  const [minimumFee, setMinimumFee] = useState<number | null>(null)

  const {
    inputValue: customFeeInner,
    debouncedValue: customFee,
    handleInputChange: handleCustomFeeChange,
  } = useDebouncedInput({ initialValue: 1, delay: 500 })

  const [satsPerByte, setSatsPerByte] = useState<number | null>(null)
  const [satsPerByteInner, setSatsPerByteInner] = useState(0)
  const [useCustomFee, setUseCustomFee] = useState(false)

  const [didSetup, setDidSetDefault] = useState(false)

  useEffect(() => {
    if (btcInfo?.sats_per_kb_btc_mempool && !didSetup) {
      const recommendedMin = btcInfo.sats_per_kb_btc_mempool.economyFee / 1000
      const min =
        typeof props.minimumFee === 'number' ? props.minimumFee : recommendedMin

      setMinimumFee(min)
      setSatsPerByte(min)
      setCustomSatsPerByte(min)
      setDidSetDefault(true)
    }
  }, [btcInfo, didSetup])

  const fee = useCustomFee ? Number(customFee) : satsPerByte
  const valid = minimumFee && fee ? fee >= minimumFee : false

  useEffect(() => {
    if (fee === null) return

    onChange({
      satsPerByte: fee,
      valid,
    })
  }, [fee, valid])

  const recommended = btcInfo?.sats_per_kb_btc_mempool
  const fees = !recommended
    ? []
    : Object.entries(recommended)
        .map(([key, value]) => {
          return {
            label: feeLabels[key as FeeRate],
            value: value / 1000,
          }
        })
        .sort((a, b) => a.value - b.value)
        .filter(({ value }) => (!minimumFee ? true : value >= minimumFee))

  const uniqueFees: { label: string; value: number }[] = !fees.length
    ? []
    : fees.reduce(
        (acc, curr, index) => {
          const existing = acc.find((item) => item.value === curr.value)
          if (index === fees.length - 1) {
            curr.label = 'Fastest'
          }

          if (!existing) {
            acc.push(curr)
          }
          return acc
        },
        [] as { label: string; value: number }[],
      )

  // const marks = !Object.keys(uniqueFees).length
  //   ? []
  //   : uniqueFees.reduce(
  //       (acc, curr) => {
  //         acc[curr.value] = <div />
  //         return acc
  //       },
  //       {} as Record<number, React.ReactNode>,
  //     )
  const lowestFee = uniqueFees.find((fee) => fee.value === minimumFee)
  const highestFee = uniqueFees.reduce((acc, curr) => {
    if (curr.value > acc.value) {
      return curr
    }
    return acc
  }, uniqueFees[0])

  const marks = uniqueFees.map((fee) => ({
    value: fee.value,
    label:
      fee.value === lowestFee?.value
        ? 'Minimum'
        : fee.value === highestFee?.value
          ? 'Fastest'
          : '',
  }))

  const selectedFee =
    fees.find((fee) => fee.value === satsPerByteInner)?.label || 'Custom'

  const setCustomSatsPerByte = (value: number) => {
    setSatsPerByteInner(value)
    handleCustomFeeChange({
      target: { value: value.toString() },
    } as React.ChangeEvent<HTMLInputElement>)
  }

  return minimumFee === null ? null : (
    <div className="space-y-3">
      <div className="flex items-center justify-between">
        <Label className="text-sm font-medium">Miner Fee</Label>
        <div className="flex items-center gap-1.5 text-xs font-medium">
          <div className="text-muted-foreground text-xs">{selectedFee}</div>
          <Input
            type="number"
            value={customFeeInner}
            onChange={(e) => {
              setUseCustomFee(true)
              setCustomSatsPerByte(Number(e.target.value))
            }}
            className="max-w-16"
            min={minimumFee ?? 1}
            step={1}
          />
          sats/byte
        </div>
      </div>
      <Slider
        value={[satsPerByteInner]}
        min={minimumFee}
        max={highestFee?.value}
        step={1}
        thumbVariant="outline"
        marks={marks}
        onValueChange={(value) => {
          setUseCustomFee(false)

          const v = value[0]
          setCustomSatsPerByte(v)
        }}
        onValueCommit={(value) => {
          setUseCustomFee(false)

          const v = value[0]
          setCustomSatsPerByte(v)
          setSatsPerByte(v)
        }}
      />

      <div className={`text-destructive text-xs ${cn({ invisible: valid })}`}>
        Minimum fee is {minimumFee} sats/byte
      </div>
    </div>
  )
}
