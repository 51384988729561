export const Env = {
  DEV: import.meta.env.DEV,
  get PROD() {
    return import.meta.env.PROD && !this.SIGNET
  },
  SIGNET: import.meta.env.VITE_SIGNET === 'true',
  get BASE_URL() {
    return this.SIGNET ? 'https://signet.ark.page' : 'https://ark.page'
  },

  get ORDINALS_EXPLORER_BASE_URL() {
    return !this.PROD ? 'https://signet.ordinals.com' : 'https://ordiscan.com'
  },

  BLOCK_EXPLORER_BASE_URL: 'https://mempool.space',

  get POSTHOG_API_KEY() {
    return this.PROD
      ? 'phc_CLBc09VZ4SBtUupzsbXVdXWh8JVWEFOhcoKbJo28MzQ'
      : undefined
  },
}
