import { Button } from './ui/button'
import { Authentication } from './Authentication'
import { useState } from 'react'
import { LoginBenefits } from '@/pages/LoginBenefits'

interface Props {
  onContinue: () => void
}

export function ArchiveInterstitial(props: Props) {
  const { onContinue } = props
  const [showLogin, setShowLogin] = useState(false)

  return (
    <div>
      {showLogin && (
        <Authentication view="login" inline onSuccess={onContinue} hideHeader />
      )}

      {showLogin && (
        <div className="relative mt-6">
          <div className="absolute inset-0 flex items-center">
            <span className="w-full border-t" />
          </div>
          <div className="relative mb-6 flex justify-center text-xs uppercase">
            <span className="bg-background text-muted-foreground px-2 tracking-wider">
              benefits
            </span>
          </div>
        </div>
      )}
      <LoginBenefits />
      <div className="mx-auto mt-6 flex w-full flex-col items-center gap-2">
        {!showLogin && (
          <Button
            variant="theme"
            onClick={() => setShowLogin(true)}
            className="w-full"
          >
            Login
          </Button>
        )}
        <Button
          variant={'outline'}
          onClick={onContinue}
          className="w-full pl-6"
        >
          Skip &rarr;
        </Button>
      </div>
    </div>
  )
}
