import * as React from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'

import { cn } from '@/lib/utils'
import { cva, type VariantProps } from 'class-variance-authority'

const smallSliderVariants = cva('', {
  variants: {
    variant: {
      default: 'bg-primary border-primary',
      theme: 'bg-theme border-theme',
    },
  },
  defaultVariants: { variant: 'default' },
})

interface Props extends VariantProps<typeof smallSliderVariants> {}

const SmallSlider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & Props
>(({ className, variant, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex w-full touch-none select-none items-center',
      className,
    )}
    {...props}
  >
    <SliderPrimitive.Track className="bg-secondary relative h-1.5 w-full grow overflow-hidden rounded-full">
      <SliderPrimitive.Range
        className={cn('absolute h-full', smallSliderVariants({ variant }))}
      />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb
      className={cn(
        `border-border ring-offset-background focus-visible:ring-ring block size-1.5 rounded-r-full border transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50`,
        { 'size-0 border-none': props.value?.[0] === 0 },
        smallSliderVariants({ variant }),
      )}
    />
  </SliderPrimitive.Root>
))
SmallSlider.displayName = SliderPrimitive.Root.displayName

export { SmallSlider }
