import { cn } from '@/lib/utils'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

interface Props {
  className?: string
  content: string
}

export function MarkdownView(props: Props) {
  const { className, content } = props

  return (
    <div
      className={cn('prose dark:prose-invert prose-lg max-w-full', className)}
    >
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={{
          a: ({ href, children }) => (
            <a href={href} target="_blank" rel="noreferrer">
              {children}
            </a>
          ),
          // img: (props) => {
          //   console.log(props)
          //   return (
          //     <img
          //       src={props.src}
          //       alt={props.alt}
          //       className="w-full rounded-md"
          //     />
          //   )
          // },
        }}
      >
        {content}
      </Markdown>
    </div>
  )
}
