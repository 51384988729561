import crypto from 'crypto'
import { Env } from './env.js'
import { POW, POWEstimate } from '../interface.js'

const SATS_PER_BITCOIN = 100_000_000 // FIXED. Do not change!!

const MIN_SATS_PER_KB = 4_000 // Minimum fee for Bitcoin mempool

const BASE_URL = !Env.PROD
  ? 'https://signet.canonic.xyz'
  : 'https://btc.canonic.xyz'

async function getPrice() {
  const res = await fetch(`${BASE_URL}/price`)
  const data = (await res.json()) as {
    query_seconds: number
    ticker: 'BTC'
    price: number
    currency: string
    dust_limit: number
    sats_per_kb: number
    sats_per_kb_min?: number
    sats_per_kb_btc_mempool?: {
      fastestFee: number
      halfHourFee: number
      hourFee: number
      economyFee: number
      minimumFee: number
    }
  }
  if (
    !Env.SIGNET &&
    data.sats_per_kb_btc_mempool &&
    data.sats_per_kb_btc_mempool.economyFee < MIN_SATS_PER_KB
  ) {
    data.sats_per_kb_btc_mempool.economyFee = Math.min(
      MIN_SATS_PER_KB,
      data.sats_per_kb_btc_mempool.hourFee,
    )
  }

  const dust_usd = (data.dust_limit / SATS_PER_BITCOIN) * data.price

  const { dust_limit, ...rest } = data

  return { ...rest, dust_usd, dust_sats: dust_limit }
}

const sha256 = (data: string | Buffer) =>
  crypto.createHash('sha256').update(data).digest('hex')

const SIGNET_MULTIPLIER = 1_000_000_000_000_000_000

async function getPOW(
  txids: string[],
  ticker: 'BTC' | 'SIGNET' = !Env.PROD ? 'SIGNET' : 'BTC',
) {
  let lastError: Error | null = null

  for (let i = 0; i < 3; i++) {
    try {
      const res = await fetch(`${BASE_URL}/pow/txids`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          txids,
          multiplier: ticker === 'SIGNET' ? SIGNET_MULTIPLIER : undefined,
        }),
      })

      const data = (await res.json()) as POW | { error: string }

      if ('error' in data) {
        throw new Error(data.error)
      }

      return data
    } catch (err) {
      lastError = err as Error
      if (i < 2) {
        // Don't wait on last iteration
        await new Promise((resolve) => setTimeout(resolve, 1000))
      }
    }
  }

  throw lastError || new Error('Failed to get POW after 3 retries')
}

async function getPOWEstimate(
  sats: number,
  ticker: 'BTC' | 'SIGNET' = !Env.PROD ? 'SIGNET' : 'BTC',
) {
  const url = new URL(`${BASE_URL}/pow/estimate`)
  url.searchParams.set('satoshis', sats.toString())

  // SIGNET doesn't uses hashes, so multiply it to get usable numbers
  if (ticker === 'SIGNET') {
    url.searchParams.set('multiplier', SIGNET_MULTIPLIER.toString())
  }

  const res = await fetch(url)

  const data = (await res.json()) as POWEstimate | { error: string }

  if ('error' in data) {
    throw new Error(data.error)
  }

  return data
}

async function watchTxid(txid: string, callback: string) {
  const res = await fetch(`${BASE_URL}/watch/txid`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ txid, callback }),
  })

  const data = (await res.json()) as
    | { txid: string; success: true }
    | { error: string }

  if ('error' in data) {
    console.error('watch txid error')
    console.error(data.error)
    throw new Error(data.error)
  }

  return data
}

export const Bitcoin = {
  SATS_PER_BITCOIN,
  getPrice,
  sha256,
  getPOW,
  getPOWEstimate,
  watchTxid,
}
